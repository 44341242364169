import * as _ from 'lodash';

import { AccountPolicyPeriod, PolicyPeriod } from 'app/bop/guidewire/typings';
import { ActivatedRoute, Router } from '@angular/router';
import { InvoicesListService } from 'app/features/invoices/services/invoices-list.service';
import { SentryService } from 'app/core/services/sentry.service';
import { Component, OnDestroy, OnInit, Input, OnChanges } from '@angular/core';
import { InformService } from 'app/core/services/inform.service';
import { InsuredAccountService } from '../../features/insured-account/services/insured-account.service';
import { Subscription, Observable, of as observableOf } from 'rxjs';
import { switchMap, shareReplay } from 'rxjs/operators';
import { FeatureFlagService, BOOLEAN_FLAG_NAMES } from 'app/core/services/feature-flag.service';
import { UserService } from 'app/core/services/user.service';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import {
  isExcess,
  isBop,
  isHab,
  displayProductName,
  isAttuneWc,
} from 'app/shared/helpers/quote-details';
import {
  WITHDRAWN_RESCINDED_STATUSES,
  InsuredAccount,
} from 'app/features/insured-account/models/insured-account.model';
import { User } from '../models/user';
import { BillingToastService, BillingToast } from '../services/billing-toast.service';
import {
  getAttuneBopPolicyUrl,
  getLossRunsDocumentsUrl,
} from '../../features/documents/models/document-urls.model';
import * as moment from 'moment';
import { US_DATE_MASK } from '../../constants';
import { DocumentService } from '../../features/documents/services/document.service';
import {
  getAttuneBopPolicyFileName,
  getLossRunsDocumentsFileName,
} from '../../features/documents/models/document-file-names.model';
import {
  InternalToolsService,
  LossRunsAvailabilityInfo,
} from 'app/shared/services/internal-tools.service';
import { GWBindService } from '../services/gw-bind.service';
import {
  HIGH_HAZARD_GROUPS,
  STRONGLY_PREFERRED_HAZARD_GROUPS,
  PREFERRED_HAZARD_GROUPS,
} from 'app/workers-comp/attune/constants';
import { PreferenceService } from '../services/preference.service';
import {
  NON_PREFERRED_PREFERENCE_LEVELS,
  PREFERRED_PREFERENCE_LEVELS,
  STRONGLY_PREFERRED_PREFERENCE_LEVELS,
} from 'app/features/attune-bop/models/constants';

export const MAIN_JOB_TYPES = ['Submission', 'Rewrite', 'Renewal'];

@Component({
  selector: 'app-bound-policy-details-pane',
  templateUrl: './bound-policy-details-pane.component.html',
})
export class BoundPolicyDetailsPaneComponent implements OnInit, OnDestroy, OnChanges {
  @Input() accountId: string;
  @Input() insuredAccount: InsuredAccount;
  @Input() policyTermTransaction: PolicyPeriod;
  private sub: Subscription = new Subscription();
  private firedJobIdSentry = false;
  policyNumber: string;
  displayInvoiceLoader = false;
  secondaryPolicyId: string;

  user: User;
  insuredAccountName: string;
  billingPrepareToast: BillingToast;

  policyDocumentDownload$: Observable<any>;
  policyDocumentDownloadReady$: Observable<any>;
  lossRunsDocumentDownload$: Observable<any>;
  lossRunsDocumentDownloadReady$: Observable<any>;
  isLossRunsDownloadEnabled = false;
  hasDownloadableLossRuns = false;
  lossRunCreatedAt = '';
  changeHistory: { download$: Observable<any>; displayName: string }[];
  changeHistoryDownloadsReady$: Observable<any>;

  // Fields used to show preferred policy status and governing class for Attune WC policies only
  policyType = '';
  policyTypeTip = '';
  isStronglyPreferredPolicy = false;
  isPreferredPolicy = false;
  isNonPreferredPolicy = false;
  showPreference = false;
  governingClassCssClass = '';
  quoteDetails: QuoteDetails;
  showWcStronglyPreferred = false;

  constructor(
    protected insuredAccountService: InsuredAccountService,
    protected internalToolsService: InternalToolsService,
    private route: ActivatedRoute,
    protected router: Router,
    private documentService: DocumentService,
    private informService: InformService,
    private sentryService: SentryService,
    private billingListService: InvoicesListService,
    private userService: UserService,
    private amplitudeService: AmplitudeService,
    private billingToastService: BillingToastService,
    private featureFlagService: FeatureFlagService,
    private bindService: GWBindService,
    private preferenceService: PreferenceService
  ) {}

  ngOnInit() {
    this.sub.add(
      this.route.queryParams.subscribe((params) => {
        if (params.secondaryPolicyId) {
          this.secondaryPolicyId = params.secondaryPolicyId;
        }
        this.displayInvoiceLoader = !!params.waitForInvoice;
        if (params.waitForInvoice) {
          this.insuredAccountService.get(this.accountId).subscribe((insuredAccount) => {
            this.insuredAccountName = insuredAccount.companyName;
            this.billingPrepareToast = this.billingToastService.billingToast(
              `Preparing the following documents for ${this.insuredAccountName}:`,
              null,
              true,
              null,
              0
            );
            this.waitForInvoiceAvailable();
          });
        }
      })
    );

    this.sub.add(
      this.featureFlagService
        .isEnabled(BOOLEAN_FLAG_NAMES.LOSS_RUNS_DOWNLOAD)
        .pipe(
          switchMap((value: boolean) => {
            this.isLossRunsDownloadEnabled = value || false;
            if (this.isLossRunsDownloadEnabled) {
              return this.internalToolsService.getLossRunsListForPolicy(
                this.policyTermTransaction.PolicyNumber
              );
            } else {
              return observableOf(null);
            }
          }),
          switchMap((lossRunsInfo: LossRunsAvailabilityInfo | null) => {
            if (
              !lossRunsInfo ||
              !(
                lossRunsInfo.lossRunAvailable &&
                lossRunsInfo.lossRunExpiry?.isSameOrAfter(moment.now())
              )
            ) {
              return observableOf(false);
            } else {
              this.hasDownloadableLossRuns = lossRunsInfo.lossRunAvailable;
              this.lossRunCreatedAt = lossRunsInfo.lossRunCreatedAt?.format('MM-DD-YYYY') || '';
              return observableOf(true);
            }
          })
        )
        .subscribe((hasLossRun: boolean) => {
          if (hasLossRun) {
            const lossRunsDocumentUrl = getLossRunsDocumentsUrl(
              this.policyTermTransaction.PolicyNumber
            );
            const lossRunsDocumentFileName = getLossRunsDocumentsFileName(
              this.policyTermTransaction.PolicyNumber,
              this.lossRunCreatedAt
            );
            this.lossRunsDocumentDownload$ = this.documentService.getDocument(
              lossRunsDocumentUrl,
              lossRunsDocumentFileName,
              'zip'
            );
            this.lossRunsDocumentDownloadReady$ =
              this.documentService.pollDocument(lossRunsDocumentUrl);
          }
        })
    );

    if (isAttuneWc(this.policyTermTransaction)) {
      this.featureFlagService
        .isEnabled(BOOLEAN_FLAG_NAMES.SHOW_WC_STRONGLY_PREFERRED)
        .subscribe((showWcStronglyPreferred) => {
          this.showWcStronglyPreferred = showWcStronglyPreferred || false;
          this.setWcPreferenceLevel();
        });
    }

    if (isBop(this.policyTermTransaction)) {
      this.featureFlagService
        .isEnabled(BOOLEAN_FLAG_NAMES.BOP_CLASS_PREFERENCE)
        .subscribe((showBopPreference) => {
          if (showBopPreference) {
            this.setBopPreferenceLevel();
          }
        });
    }

    this.getCurrentUser();
  }

  isExcess() {
    return isExcess(this.policyTermTransaction);
  }

  isBop() {
    return isBop(this.policyTermTransaction);
  }

  isAttuneWc() {
    return isAttuneWc(this.policyTermTransaction);
  }

  isHab() {
    return isHab(this.policyTermTransaction);
  }

  displayProductName() {
    return displayProductName(this.policyTermTransaction);
  }

  getTotalCost() {
    if (!this.insuredAccount) {
      return NaN;
    }
    return parseFloat(this.policyTermTransaction.TermTotalCost_ATN.split(' ')[0]);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  waitForInvoiceAvailable() {
    this.sub.add(
      this.userService
        .getUser()
        .pipe(
          switchMap((user) => {
            return this.billingListService.waitUntilInvoiceIsAvailable(
              this.accountId,
              user.producer
            );
          })
        )
        .subscribe((isInvoiceAvailable) => {
          this.displayInvoiceLoader = false;
          this.router.navigate([], {
            queryParams: { waitForInvoice: null },
            queryParamsHandling: 'merge',
          });
          if (!isInvoiceAvailable) {
            this.sentryService.notify(
              `Failed to display toast for invoices: invoice was not available`,
              {
                metaData: {
                  accountId: this.accountId,
                },
              }
            );
            this.informService.deleteToast({ toastId: this.billingPrepareToast.id });
            this.informService.errorToast(
              `Your invoice for ${this.insuredAccountName} has failed to generate. Please contact our Customer Care Team.`,
              null,
              'Invoice failed'
            );
          } else {
            this.informService.deleteToast({ toastId: this.billingPrepareToast.id });
            this.billingToastService.billingToast(
              `These documents are ready: `,
              'view',
              false,
              (buttonClicked: boolean) => {
                if (buttonClicked) {
                  this.amplitudeService.track({
                    eventName: 'clicked_post_bind_invoice_toast',
                    detail: this.accountId,
                    useLegacyEventName: true,
                  });
                  this.navigateToInvoicePage();
                }
              },
              15000,
              true
            );
          }
        })
    );
  }

  getMainJobNumber(): string | null {
    const mainTransaction = _.get(
      this,
      'policyTermTransaction.PolicyTerm.PortalViewableTermPeriods.Entry',
      []
    ).find((displayTerm: AccountPolicyPeriod) => {
      return MAIN_JOB_TYPES.includes(displayTerm.Job.Subtype as string);
    });

    if (!mainTransaction && !this.firedJobIdSentry) {
      this.firedJobIdSentry = true;
      this.sentryService.notify(`Cannot find Submission, Renewal, or Rewrite in policy`, {
        metaData: {
          policyId: this.policyTermTransaction.PolicyNumber,
        },
      });
    }

    return _.get(mainTransaction, 'Job.JobNumber', null);
  }

  getChangeHistory(): AccountPolicyPeriod[] {
    return _.get(
      this,
      'policyTermTransaction.PolicyTerm.PortalViewableTermPeriods.Entry',
      []
    ).filter((displayTerm: AccountPolicyPeriod) => {
      const isMidtermTransaction = !MAIN_JOB_TYPES.includes(displayTerm.Job.Subtype as string);
      const isRescinded = displayTerm.Status
        ? WITHDRAWN_RESCINDED_STATUSES.includes(displayTerm.Status)
        : false;
      return isMidtermTransaction && !isRescinded;
    });
  }

  getCurrentUser() {
    this.userService.getUser().subscribe((user) => {
      this.user = user;
    });
  }

  navigateToInvoicePage() {
    this.sub.add(
      this.route.params
        .pipe(
          switchMap((params) => {
            this.accountId = params['accountId'];
            return this.userService.getUser();
          }),
          switchMap((user) => {
            return this.billingListService.getInvoiceList(
              user.producer,
              0,
              5,
              undefined,
              this.accountId,
              undefined
            );
          })
        )
        .subscribe(
          (invoiceList: BackendInvoiceSummary) => {
            const invoice = invoiceList.invoices[0];
            this.router.navigate(['bop', 'invoice', invoice.id], {
              queryParams: { token: invoice.transactionId },
              replaceUrl: true,
            });
          },
          (err) => {
            this.handleRedirectError();
          }
        )
    );
  }

  handleRedirectError() {
    this.router.navigate(['accounts'], { replaceUrl: true });
    this.informService.errorToast(
      `We encountered an error loading invoices for this account.`,
      null,
      'Invoices not found.',
      'Okay',
      null,
      0
    );
  }

  ngOnChanges(): void {
    const jobNumber = this.getMainJobNumber();
    if (jobNumber) {
      const policyDocumentUrl = getAttuneBopPolicyUrl(jobNumber);
      const policyDocumentFileName = getAttuneBopPolicyFileName(jobNumber);
      this.policyDocumentDownload$ = this.documentService.getDocument(
        policyDocumentUrl,
        policyDocumentFileName,
        'pdf'
      );
      this.policyDocumentDownloadReady$ = this.documentService.pollDocument(policyDocumentUrl);
    }
    const changeHistory = this.getChangeHistory();
    if (changeHistory.length) {
      this.changeHistory = changeHistory.map((policyChange) => {
        const url = getAttuneBopPolicyUrl(policyChange.Job.JobNumber);
        const fileName = `${policyChange.Job.DisplayType.toLowerCase().replace(' ', '-')}-${
          this.policyTermTransaction.PolicyNumber
        }.pdf`;
        return {
          download$: this.documentService.getDocument(url, fileName, 'pdf'),
          displayName: `${policyChange.Job.DisplayType} (${moment
            .utc(policyChange.EditEffectiveDate)
            .format(US_DATE_MASK)})`,
        };
      });
      const changeHistoryUrl = getAttuneBopPolicyUrl(
        changeHistory[changeHistory.length - 1].Job.JobNumber
      );
      this.changeHistoryDownloadsReady$ = this.documentService
        .pollDocument(changeHistoryUrl)
        .pipe(shareReplay());
    }
  }

  setBopPreferenceLevel() {
    this.sub.add(
      this.preferenceService
        .getPreferenceByJobNumber('bop', this.policyTermTransaction?.Job?.JobNumber)
        .subscribe((policyPreference) => {
          const preferenceLevel = policyPreference?.preferenceLevel;

          if (!preferenceLevel) {
            this.showPreference = false;
            return;
          }

          this.showPreference = true;

          if (STRONGLY_PREFERRED_PREFERENCE_LEVELS.includes(preferenceLevel)) {
            this.policyType = `Strongly Preferred Policy`;
            this.policyTypeTip = 'This business is accepted and/or targeted.';
            this.isStronglyPreferredPolicy = true;
          } else if (PREFERRED_PREFERENCE_LEVELS.includes(preferenceLevel)) {
            this.policyType = 'Preferred Policy';
            this.policyTypeTip = 'This business is accepted and/or targeted.';
            this.isPreferredPolicy = true;
          } else if (NON_PREFERRED_PREFERENCE_LEVELS.includes(preferenceLevel)) {
            this.policyType = 'Non-preferred Policy';
            this.policyTypeTip = 'This business is accepted on a limited basis.';
            this.isNonPreferredPolicy = true;
          } else {
            this.showPreference = false;
          }
        })
    );
  }

  setWcPreferenceLevel() {
    this.sub.add(
      this.bindService
        .getQuoteDetails(this.policyTermTransaction.Job.JobNumber)
        .subscribe((quoteDetails) => {
          this.quoteDetails = quoteDetails;
          if (!quoteDetails.hazardGroup) {
            this.showPreference = false;
            return;
          }

          this.showPreference = true;

          if (STRONGLY_PREFERRED_HAZARD_GROUPS.includes(quoteDetails.hazardGroup)) {
            if (this.showWcStronglyPreferred) {
              this.policyType = `Strongly Preferred Policy`;
            } else {
              this.policyType = '\u{1F525}  Hot Class Policy';
            }

            this.policyTypeTip =
              'This business is accepted and/or targeted. For more information, review our Underwriting Guidelines below.';
            this.isStronglyPreferredPolicy = true;
            this.governingClassCssClass = 'governing-class-preferred';
          } else if (PREFERRED_HAZARD_GROUPS.includes(quoteDetails.hazardGroup)) {
            this.policyType = 'Preferred Policy';
            this.policyTypeTip =
              'This business is accepted and/or targeted. For more information, review our Underwriting Guidelines below.';
            this.isPreferredPolicy = true;
            this.governingClassCssClass = 'governing-class-preferred';
          } else if (HIGH_HAZARD_GROUPS.includes(quoteDetails.hazardGroup)) {
            this.policyType = 'Non-preferred Policy';
            this.policyTypeTip =
              'This business is accepted on a limited basis. For more information, review our Underwriting Guidelines below.';
            this.isNonPreferredPolicy = true;
            this.governingClassCssClass = 'governing-class-non-preferred';
          } else {
            this.showPreference = false;
          }
        })
    );
  }
}
