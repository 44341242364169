// Libraries
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { mapValues, keyBy } from 'lodash';
// Constants
import {
  AVAILABLE_AGGREGATE_LIMITS_FOR_CYBER_LIABILITY,
  BOP_V2_CYBER_OPTIONS,
  BOP_V2_CYBER_SPLIT_STATES,
  BOP_V1_EXCLUDED_CYBER_STANDARD_ENDORSEMENT_STATES,
  CyberProductType,
  LimitOption,
} from 'app/features/attune-bop/models/constants';
// Helpers
import { shouldShowInvalid, getControl } from 'app/shared/helpers/form-helpers';
import { numberToMoneyString } from '../../../../shared/helpers/number-format-helpers';
import {
  CoalitionCyberQuestion,
  EngagedIndustriesNestedQuestion,
} from '../../../coalition/models/cyber-typings.model';
import {
  coalitionCyberIndustryFormatter,
  coalitionCyberIndustryQueryMethod,
} from '../../../../shared/form-dsl/services/form-dsl-search.service';
import {
  MOST_POPULAR_AGGREGATE_LIMIT_INDEX,
  ESSENTIAL_AGGREGATE_LIMIT_INDEX,
  PRIOR_CLAIMS_AMOUNT_OPTIONS,
} from '../../../coalition/models/cyber-constants.model';

@Component({
  selector: 'app-attune-bop-cyber-liability-coverage',
  templateUrl: './attune-bop-cyber-liability-coverage.component.html',
})
export class AttuneBopCyberLiabilityCoverageComponent {
  @Input() form: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() bopVersion: 1 | 2;
  @Input() baseState: string;
  @Input() coalitionCyberMappedIndustries: string[] = [];

  @Input() accountId = '';
  @Input() domain = '';

  @Input() activeCyberPrice: number;
  @Input() endorsementPrice: number;

  @Output() openPriceComparisonModal = new EventEmitter<void>();

  companyIndustryControl = CoalitionCyberQuestion.COMPANY_INDUSTRY_ID;
  hasTechEOControl = CoalitionCyberQuestion.HAS_TECH_EO;
  annualRevenueControl = CoalitionCyberQuestion.COMPANY_REVENUE;
  netRevenueControl = CoalitionCyberQuestion.COMPANY_GROSS_PROFIT_NET_REVENUE;
  aggregateLimitControl = CoalitionCyberQuestion.AGGREGATE_LIMIT;

  awareOfPriorClaimsControl = CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS;
  awareOfPriorClaimsCountControl = CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_COUNT;
  awareOfPriorClaimsAmountControl = CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT;
  awareOfPriorClaimsAmountOver500KControl =
    CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT_MORE_THAN_500K;
  priorClaimsControl = CoalitionCyberQuestion.PRIOR_CLAIMS;
  priorClaimsExplanationControl = CoalitionCyberQuestion.PRIOR_CLAIMS_EXPLANATION;

  dualControl5kControl = CoalitionCyberQuestion.DUAL_CONTROL_5K;
  administrativeDualCtrlControl = CoalitionCyberQuestion.ADMINISTRATIVE_DUAL_CONTROL;
  mfaEmailControl = CoalitionCyberQuestion.MFA_EMAIL;
  mfaRemoteAccessControl = CoalitionCyberQuestion.MFA_REMOTE_ACCESS;
  mfaVpnControl = CoalitionCyberQuestion.MFA_VPN;
  mfaOtherControl = CoalitionCyberQuestion.MFA_OTHER_PRIVILEGED_ACCOUNTS;

  engagedIndustriesControl = CoalitionCyberQuestion.ENGAGED_INDUSTRIES;
  engagedIndustryAdult = EngagedIndustriesNestedQuestion.ADULT;
  engagedIndustryCrypto = EngagedIndustriesNestedQuestion.CRYPTO_BLOCKCHAIN;
  engagedIndustryGambling = EngagedIndustriesNestedQuestion.GAMBLING;
  engagedIndustryMsp = EngagedIndustriesNestedQuestion.MSP_MSSP_RNASP;
  engagedIndustryPaymentProcessing = EngagedIndustriesNestedQuestion.PAYMENT_PROCESSING;

  coalitionCyberIndustryFormatter = coalitionCyberIndustryFormatter;
  coalitionCyberIndustryQueryMethod = coalitionCyberIndustryQueryMethod;

  standaloneCyberLimits = {
    '250k limit, 5k retention': MOST_POPULAR_AGGREGATE_LIMIT_INDEX,
    '100k limit, 1k retention': ESSENTIAL_AGGREGATE_LIMIT_INDEX,
  };

  standaloneCyberLimitPillTags = {
    '250k limit, 5k retention': 'Most popular',
  };

  yesNoOptions = { Yes: 'Yes', No: 'No' };
  dualControlOptions = { Yes: 'Yes', No: 'No', 'N/A': 'N/A' };
  priorClaimsAmountOptions = PRIOR_CLAIMS_AMOUNT_OPTIONS;

  mfaRemoteAccessOptions = {
    Yes: 'Yes',
    No: 'No',
    'N/A - No remote access allowed': 'N/A - No remote access allowed',
  };
  mfaVpnOptions = { Yes: 'Yes', No: 'No', 'N/A - No VPN allowed': 'N/A - No VPN allowed' };
  mfaOtherAccountsOptions = {
    Yes: 'Yes',
    No: 'No',
    'On administrative accounts and all cloud services where supported':
      'On administrative accounts and all cloud services where supported',
  };

  constructor() {}

  shouldShowInvalid(field: string): boolean | undefined {
    return shouldShowInvalid(field, this.form, this.submitted);
  }

  get cyberOptions() {
    return BOP_V2_CYBER_OPTIONS?.[this.baseState] || BOP_V2_CYBER_OPTIONS.default;
  }

  availableAggregateLimits() {
    if (this.bopVersion === 1) {
      return AVAILABLE_AGGREGATE_LIMITS_FOR_CYBER_LIABILITY;
    }

    const options = this.cyberOptions.aggregateLimitOptions;
    if (!options) {
      throw new Error(`No aggregate limits mapped for ${this.baseState}`);
    }
    return this.createRadioOptionsForLimits(options);
  }

  availableFirstPartyLimits() {
    const options = this.cyberOptions.firstPartyLimitOptions;
    if (!options) {
      throw new Error(`No first party limits mapped for ${this.baseState}`);
    }
    return this.createRadioOptionsForLimits(options);
  }

  cyberDeductible() {
    const { deductible } = this.form.value;
    return numberToMoneyString(deductible);
  }

  splitLimitState() {
    return BOP_V2_CYBER_SPLIT_STATES.includes(this.baseState);
  }

  splitLimitTooltipText() {
    // NY is a split state but it has a different structure, it uses a third party annual aggregate limit which is equal to the first party limit.
    if (this.baseState === 'NY') {
      return 'Third party annual aggregate limits are equal to the first party limit.';
    }
    return 'Third party defense and indemnity limits are half of the first party limit.';
  }

  productType(): CyberProductType {
    const selectedCyberCoverageControl = getControl(this.form, 'selectedCyberCoverage');

    return selectedCyberCoverageControl ? selectedCyberCoverageControl.value : 'none';
  }

  getRevenueError() {
    return this.form.errors && this.form.errors.grossProfitGreaterThanCompanyRevenue;
  }

  getCyberQuestionOptions() {
    if (
      BOP_V1_EXCLUDED_CYBER_STANDARD_ENDORSEMENT_STATES.includes(this.baseState) &&
      this.bopVersion === 1
    ) {
      return {
        'Active Cyber': 'coalition-cyber',
        None: 'none',
      };
    }

    return {
      'Active Cyber': 'coalition-cyber',
      'Standard Endorsement': 'endorsement',
      None: 'none',
    };
  }

  getSelectedCyberOption() {
    const control = this.form.get('selectedCyberCoverage');
    if (control) {
      return control.value;
    }
    return 'none';
  }

  openModal() {
    this.openPriceComparisonModal.emit();
  }

  private createRadioOptionsForLimits(listOfObjects: LimitOption[]) {
    return mapValues(
      keyBy(listOfObjects, (obj) => numberToMoneyString(obj.limit)),
      'limit'
    );
  }
}
