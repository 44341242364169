<app-quote-stepped-sidebar
  class="app-page-sidebar app-page-sidebar__quote app-page-sidebar__bop"
  [product]="getProductBadgeName()"
  pasSource="attune"
  [steppedFormService]="formService"
  (navigateToStep)="handleNavigateToSlug($event)"
  [accountId]="accountId"
  [insAccount]="insAccount$.getValue()"
  [sidebarTitle]="!createQuoteFromMostRecentQuote && isEditing ? 'Edit quote' : 'New quote'"
  [fillInIneligibleLocation]="populateIneligibleLocation.bind(this)"
  [fillInHappyPath]="populateKnownGoodHappyPathValues.bind(this)"
></app-quote-stepped-sidebar>

<div class="app-page-form-scrollable-wrapper">
  <div class="app-page-form" content>
    <main *ngIf="loading" class="placeholder-form-container">
      <div class="placeholder-h1"></div>
      <div class="placeholder-form-label"></div>
      <div class="placeholder-form-input"></div>
      <div class="placeholder-form-label"></div>
      <div class="placeholder-form-input"></div>
      <div class="placeholder-form-label"></div>
      <div class="placeholder-form-input"></div>
      <div class="placeholder-form-label"></div>
      <div class="placeholder-form-input"></div>
      <div class="placeholder-form-submit"></div>
    </main>
    <ng-container *ngIf="!loading">
      <a
        *ngIf="!isFirstStep()"
        class="emphasized-link form-previous-link"
        (click)="clickBackward()"
        (keyup.enter)="clickBackward()"
      >
        <span class="icon-full-arrow-left button-icon"></span>Previous step
      </a>
      <form autocomplete="off" [formGroup]="form" (ngSubmit)="handleSubmit($event)" novalidate>
        <fieldset [disabled]="isNavigating()">
          <section *ngIf="isCurrentStep('guidelines')">
            <h1 [ngClass]="{ 'form-header-below-link': !isFirstStep() }">Guidelines</h1>
            <h3>Please review the following guidelines.</h3>
            <ul class="guideline-list">
              <li *ngFor="let guideline of guidelinesData">
                {{ guideline }}
              </li>
            </ul>
            <div
              class="additional-guidelines-list"
              *ngIf="additionalGuidelines && additionalGuidelines.length"
            >
              <h3>Recently updated:</h3>
              <ul class="guideline-list guideline-list__no-margin">
                <li *ngFor="let newGuideline of additionalGuidelines">
                  {{ newGuideline }}
                </li>
              </ul>
            </div>
            <app-form-field-checkbox
              inputId="accept-guidelines"
              labelText="I have read and confirm that the business follows these guidelines"
              nameOfFormControl="guidelines"
              [submitted]="submitted()"
              [form]="form"
            >
            </app-form-field-checkbox>
          </section>
          <section *ngIf="isCurrentStep('policy-info')" class="form-animation">
            <h1 [ngClass]="{ 'form-header-below-link': !isFirstStep() }">Policy Info</h1>
            <div class="form-split">
              <app-form-field-text
                inputId="effectiveDate"
                labelText="Effective date"
                [form]="form.get('policyInfo')"
                [submitted]="submitted()"
                nameOfFormControl="effectiveDate"
                labelText="Effective date"
                [readonly]="uwDecisionLoading"
                (focusOut)="updateIsEffectiveDateWarned()"
                appDateMask
              >
                <span
                  role="label"
                  class="more-info-tip tool-tip tool-tip__large"
                  data-tooltip="Please use the appropriate effective date, as changing it may impact the rating plan."
                  (mouseover)="hideEffectiveDateBlink()"
                  (focus)="hideEffectiveDateBlink()"
                  (mouseout)="cancelEffectiveDateBlinkHide()"
                  (blur)="cancelEffectiveDateBlinkHide()"
                >
                  <span class="icon icon-question">
                    <span
                      class="notice-dot"
                      *ngIf="!hasSeenEffectiveDateInfo() && !isHoveringOnDateTooltip"
                    ></span>
                  </span>
                </span>
              </app-form-field-text>
              <div class="helper-card-container">
                <app-form-field-select
                  inputId="baseState"
                  [form]="form.get('policyInfo')"
                  [submitted]="submitted()"
                  labelText="Base State"
                  nameOfFormControl="baseState"
                  [availableOptions]="usStateService.getUsStateAbbreviations()"
                  [readonly]="uwDecisionLoading"
                >
                </app-form-field-select>
                <div *ngIf="isEditing" class="helper-card helper-card__no-margin">
                  <div class="pill-tag pill-tag__main">Note</div>
                  <p>
                    Have a special coverage request?
                    <a [routerLink]="['/support/form/coverage-question']"
                      >Request coverage change</a
                    >
                  </p>
                </div>
              </div>
            </div>
            <app-dialog-box
              *ngIf="isEffectiveDateInPast"
              header="Backdated quotes will be reviewed"
              type="warning"
              class="form-field js-backdate-warning-dialog"
              id="dateInPastDialog"
            >
              Please note that you will be asked to provide a No Known Loss letter at the time of
              bind to request backdated coverage. All requests to backdate coverage will be sent to
              our Underwriting Team for review.
              <div class="dialog-text-links">
                <a
                  class="dialog-text-link"
                  href="https://myattune-web-assets.s3.us-east-2.amazonaws.com/public/documents/Attune+BOP+NKLL+Template.pdf"
                  target="_blank"
                  data-amplitude-input-name="bop_quote_download_nkll"
                >
                  Download No Known Loss Letter template
                </a>
              </div>
            </app-dialog-box>
            <app-dialog-box
              *ngIf="isEffectiveDateNinetyDaysInFuture"
              header="This effective date can be quoted, but can not yet be bound"
              type="warning"
              class="form-field js-backdate-warning-dialog"
              id="ninetyDaysDialog"
            >
              Please note that quotes can not be bound more than 90 days ahead of their effective
              date. You can still quote with the selected effective date, but will not yet be able
              to bind.
            </app-dialog-box>
            <app-form-field-select
              inputId="organizationType"
              labelText="Organization type"
              [form]="form.get('policyInfo')"
              [submitted]="submitted()"
              [availableOptions]="organizationTypes"
              [hidden]="hideOrganizationType"
              nameOfFormControl="organizationType"
              [readonly]="uwDecisionLoading"
            >
            </app-form-field-select>
            <app-form-field-text
              inputId="domain"
              labelText="Website or email domain"
              specifierText="(optional)"
              questionNote="Please provide the domain for the primary website or email owned and operated by the insured. This information is used to accurately price this risk. The policyholder will not be contacted or notified as part of this scan."
              [hidden]="!showDomain"
              [form]="domainFormGroup"
              [submitted]="submitted()"
              nameOfFormControl="domain"
              [readonly]="uwDecisionLoading"
              appRemoveWhitespaceMask
            >
            </app-form-field-text>
            <app-form-field-text
              inputId="yearsInBusiness"
              [form]="form.get('policyInfo')"
              [submitted]="submitted()"
              labelText="Years in business"
              nameOfFormControl="yearsInBusiness"
              appDelimitedNumberMask
              [readonly]="uwDecisionLoading"
            >
            </app-form-field-text>
            <section
              *ngFor="let location of locationAddressesFormArray().controls; index as locationIndex"
              class="multi-form-item"
            >
              <app-form-field-address
                labelText="Location business address"
                prefix="location-{{ locationIndex + 1 }}"
                [form]="location"
                [submitted]="submitted()"
                [readonly]="uwDecisionLoading"
              ></app-form-field-address>
              <div class="nav-button-group">
                <a
                  *ngIf="moreThanOneLocation()"
                  class="button button__small button__secondary remove-location"
                  (click)="removeLocation(locationIndex)"
                  (keyup.enter)="removeLocation(locationIndex)"
                  [ngClass]="{ button__disabled: uwDecisionLoading }"
                  [attr.aria-disabled]="uwDecisionLoading"
                >
                  Remove
                </a>
              </div>
              <div *ngIf="getUniqueAddressError(locationIndex)" class="validation">
                <div class="validation-message">Each location must have a unique address.</div>
              </div>
            </section>
            <button
              class="button button__secondary button__full-width js-add-location"
              [ngClass]="{
                'button__no-margin': form.get('policyInfo.agentLicenseNumber').disabled
              }"
              type="button"
              (click)="addLocation()"
              [disabled]="uwDecisionLoading"
            >
              Add location
            </button>

            <div class="form-split">
              <app-form-field-text
                inputId="agentName"
                [form]="form.get('policyInfo')"
                [submitted]="submitted()"
                labelText="Agent Name"
                nameOfFormControl="agentName"
              >
              </app-form-field-text>

              <app-form-field-text
                inputId="agentLicenseNumber"
                [form]="form.get('policyInfo')"
                [submitted]="submitted()"
                labelText="Agent FL License Number"
                nameOfFormControl="agentLicenseNumber"
                placeholder="A123456"
                maxLength="7"
                appAlphanumericMask
              >
              </app-form-field-text>
            </div>
          </section>

          <section *ngIf="isCurrentStep('location-\\d+')" class="form-animation">
            <app-location-form
              [locationIndex]="locationIndex()"
              [locationFormGroup]="currentLocationDetails()"
              [availablePropertyDeductibles]="
                availablePropertyDeductiblesForLocation[locationIndex() - 1]
              "
              (navigateToStep)="handleNavigateToSlug($event)"
              [submitted]="submitted()"
              [bopVersion]="bopVersion()"
            ></app-location-form>
          </section>

          <section *ngIf="isCurrentStep('building-\\d+-\\d+')" class="form-animation">
            <app-building-exposure-form
              [bopVersion]="bopVersion()"
              [locationIndex]="locationIndex()"
              [buildingIndex]="buildingIndex()"
              [displayDeductibleChangedWarning]="displayDeductibleChangedWarning"
              [buildingExposureFormGroup]="currentBuildingExposure()"
              [buildingLessorsRiskFormGroup]="currentBuildingLessorsRisk()"
              [currentLocationDetails]="currentLocationDetails()"
              [submitted]="submitted()"
              [isEligibilityOverride]="isEligibilityOverride()"
              [baseState]="baseState()"
            ></app-building-exposure-form>
          </section>

          <section *ngIf="isCurrentStep('building-\\d+-\\d+-lessors-risk')" class="form-animation">
            <app-building-lessors-risk-form
              [accountId]="accountId"
              [tsRequestId]="tsrequestid"
              [bopVersion]="bopVersion()"
              [locationIndex]="locationIndex()"
              [buildingIndex]="buildingIndex()"
              [buildingExposureFormGroup]="currentBuildingExposure()"
              [buildingLessorsRiskFormGroup]="currentBuildingLessorsRisk()"
              [formService]="formService"
              [submitted]="submitted()"
              (openFirstTimeLROModal)="toggleFirstTimeLROModal()"
            >
            </app-building-lessors-risk-form>
          </section>

          <section *ngIf="isCurrentStep('building-\\d+-\\d+-coverages')" class="form-animation">
            <h1 class="form-header-below-link">
              Location {{ locationIndex() }} – Building {{ buildingIndex() }} – Coverages
            </h1>
            <div id="building-{{ locationIndex() }}-{{ buildingIndex() }}">
              <app-utility-services-coverage
                [building]="currentBuildingCoverage()"
                [submitted]="submitted()"
                [state]="currentLocationDetails().get('state').value"
                [milesToOcean]="currentLocation().value.locationPrefill.isoDistToCoastRangeCode"
                prefix="building-{{ locationIndex() }}-{{ buildingIndex() }}"
              >
              </app-utility-services-coverage>

              <app-form-field-radio
                inputId="building-{{ locationIndex() }}-{{
                  buildingIndex()
                }}-businessIncomeAndExtraExpensesIndemnityInMonths"
                cssClass="js-biee-radio"
                [form]="currentBuildingCoverage()"
                [submitted]="submitted()"
                questionText="BI/EE Period of Indemnity (Months)"
                [options]="businessIncomeAndExtraExpensesIndemnityInMonthsOptions"
                nameOfFormControl="businessIncomeAndExtraExpensesIndemnityInMonths"
                tooltipText="Business Interruption & Extra Expense (BI/EE) responds on an
              Actual Loss Sustained (ALS) basis so there is no scheduled limit. The Period of
              Indemnity is the period of time the benefits are payable under the policy.
              BI/EE contemplates the net income that would have been earned by the applicant
              including normal operating expenses such as payroll."
              >
              </app-form-field-radio>

              <app-catastrophe-coverages
                (openWindDeclineModal)="openWindDeclineModal($event)"
                [building]="currentBuildingCoverage()"
                [exposure]="currentBuildingExposure()"
                [submitted]="submitted()"
                [state]="currentLocationDetails().get('state').value"
                [constructionType]="currentBuildingExposure().get('constructionType').value"
                [propertyDeductible]="currentLocationDetails().get('propertyDeductible').value"
                [businessType]="currentBuildingExposure().get('businessType').value"
                [lessorsRisk]="currentBuildingExposure().get('lessorsRisk').value"
                [milesToOcean]="currentLocation().value.locationPrefill.isoDistToCoastRangeCode"
                [bopVersion]="bopVersion()"
                prefix="building-{{ buildingIndex() }}"
              >
              </app-catastrophe-coverages>

              <h3 class="question-subsection-header">Additional Building Coverage</h3>
              <app-form-field-radio
                inputId="{{ prefix }}-equipmentBreakdownCoverageOptedIn"
                [form]="currentBuildingCoverage()"
                [submitted]="submitted()"
                questionText="Would you like Equipment Breakdown Coverage?"
                nameOfFormControl="equipmentBreakdownCoverageOptedIn"
                tooltipText="This pays for direct physical damage to Covered Property that is the direct
              result of an “accident” or “electronic circuitry impairment”. Examples can include
              mechanical breakdown, electric arcing, explosion, or damage to steam boilers. A $50,000
              sub-limit is included for Data Restoration, Expediting Expenses, Hazardous Substances, and
              Off Premises Equipment Breakdown."
              >
              </app-form-field-radio>

              <app-ordinance-law-coverage
                [coverage]="currentBuildingCoverage()"
                [submitted]="submitted()"
                prefix="building-{{ locationIndex() }}-{{ buildingIndex() }}"
              >
              </app-ordinance-law-coverage>

              <app-spoilage-coverage
                [coverage]="currentBuildingCoverage()"
                [exposure]="currentBuildingExposure()"
                [submitted]="submitted()"
                prefix="building-{{ locationIndex() }}-{{ buildingIndex() }}"
              >
              </app-spoilage-coverage>

              <app-debris-removal-coverage
                [coverage]="currentBuildingCoverage()"
                [state]="currentLocationDetails().get('state').value"
                [submitted]="submitted()"
                prefix="building-{{ locationIndex() }}-{{ buildingIndex() }}"
              >
              </app-debris-removal-coverage>
            </div>
          </section>

          <section *ngIf="isCurrentStep('liability-coverages')" class="form-animation">
            <h1 class="form-header-below-link">Liability Coverages</h1>

            <div class="helper-card-container">
              <app-form-field-radio
                inputId="quote-limitPerOccurrenceOfLiabilityAndMedicalExpenses"
                [form]="form.get('liabilityCoverages')"
                [submitted]="submitted()"
                questionText="Per Occurrence"
                nameOfFormControl="limitPerOccurrenceOfLiabilityAndMedicalExpenses"
                [options]="getPerOccurenceLimit()"
                tooltipText="The Per Occurrence Limit is the maximum amount the policy will pay for all
              covered losses from a single occurrence, regardless of the number of persons injured or
              the number of parties claiming property damage. The policy limits are subject to an
              Aggregate Limit. The Aggregate Limit is the most the policy will pay in total,
              for all claims in a single policy term."
              >
              </app-form-field-radio>
              <app-form-field-radio
                inputId="quote-excessLiabilityOptIn"
                cssClass="js-excess-liability-opt-in form-subsection"
                [form]="form"
                [submitted]="submitted()"
                [emitClicks]="true"
                questionText="Would you like Core Specialty Excess Liability coverage?"
                nameOfFormControl="excessLiabilityOptIn"
              >
                <p
                  *ngIf="form.get('excessLiabilityOptIn').value === true"
                  class="
                    dialog-text dialog-text__information dialog-text__margin-top
                    js-excess-manual-quoting
                  "
                >
                  Quote requests are handled manually. Please enter excess information on the next
                  page and we will reach out to you regarding next steps in 24 hours.
                  <a
                    data-amplitude-input-name="request_manual_excess_info_link"
                    href="https://blog.attuneinsurance.com/were-migrating-aig-xs-policies-to-core-specialty/"
                    target="_blank"
                    >More info.</a
                  >
                </p>
              </app-form-field-radio>

              <div class="helper-card">
                <div class="pill-tag pill-tag__main">Note</div>
                <p>The Aggregate Limit will be set at 2 times the selected Per Occurrence Limit</p>
              </div>
            </div>

            <div
              class="js-liquor-liability-section"
              *ngIf="form.get('liabilityCoverages.liquorLiability.optedIn').enabled"
            >
              <app-form-field-radio
                inputId="quote-liquorLiabililityOptIn"
                [form]="form.get('liabilityCoverages.liquorLiability')"
                [submitted]="submitted()"
                questionText="Include Liquor Liability Coverage?"
                nameOfFormControl="optedIn"
                tooltipText="This coverage pays for “bodily injury” and “property damage” sustained
              by one or more persons or organizations as the re­sult of selling, serving or
              furnishing of alco­holic beverages to any person."
              >
              </app-form-field-radio>

              <div
                class="form-subsection js-liquor-liability-additional-questions"
                *ngIf="form.get('liabilityCoverages.liquorLiability.optedIn').value"
              >
                <app-form-field-select
                  inputId="liquor-liability-eachCommonCauseLimit"
                  [form]="form.get('liabilityCoverages.liquorLiability')"
                  [submitted]="submitted()"
                  labelText="Each Common Cause Limit"
                  nameOfFormControl="eachCommonCauseLimit"
                  [availableOptions]="availableLimitsForLiquorLiabilityEachCommonCause"
                >
                </app-form-field-select>
                <app-form-field-select
                  inputId="liquor-liability-aggregateLimit"
                  [form]="form.get('liabilityCoverages.liquorLiability')"
                  [submitted]="submitted()"
                  [readonly]="baseState() !== 'IL'"
                  labelText="Aggregate Limit"
                  nameOfFormControl="aggregateLimit"
                  [availableOptions]="availableLimitsForLiquorLiabilityAggregate"
                >
                </app-form-field-select>
              </div>

              <div
                class="validation"
                *ngIf="submitted() && form.get('liabilityCoverages.liquorLiability.optedIn').errors"
              >
                <div
                  class="validation-message"
                  *ngIf="
                    form.get('liabilityCoverages.liquorLiability.optedIn').errors
                      .alcoholSalesRequired
                  "
                >
                  At least one location must either have alcohol sales or be a BYOB classification
                  to select Liquor Liability coverage.
                </div>
                <div
                  class="validation-message"
                  *ngIf="
                    form.get('liabilityCoverages.liquorLiability.optedIn').errors
                      .abovePerOccurenceLimit
                  "
                >
                  {{
                    form.get('liabilityCoverages.liquorLiability.optedIn').errors
                      .abovePerOccurenceLimit
                  }}
                </div>
              </div>
            </div>

            <div class="form-subsection js-liquor-license-number">
              <app-form-field-text
                inputId="quote-liquorLiabililityLicenseNumber"
                [form]="form.get('liabilityCoverages.liquorLiability')"
                [submitted]="submitted()"
                labelText="Liquor License Number"
                nameOfFormControl="liquorLicenseNumber"
              >
              </app-form-field-text>
            </div>
            <div
              class="form-subsection js-liquor-liability-underwriting-questions"
              *ngIf="form.get('liabilityCoverages.liquorLiability.optedIn').value"
            >
              <app-form-field-radio
                inputId="quote-liquorLiabililityLicenseSuspended"
                [form]="
                  form.get('liabilityCoverages.eligibilityUWQuestions.liquorLiabilityUWQuestions')
                "
                [submitted]="submitted()"
                questionText="Have you ever been assessed a fine for violation of a law concerning the sale of alcohol or had your liquor license suspended in the last 10 years?"
                nameOfFormControl="liquorLicenseSuspendedInLast10Years"
              >
              </app-form-field-radio>

              <app-form-field-radio
                inputId="quote-liquorLiabililityPatronAgeUnder40"
                [form]="
                  form.get('liabilityCoverages.eligibilityUWQuestions.liquorLiabilityUWQuestions')
                "
                [submitted]="submitted()"
                questionText="Do you check identification of all patrons who appear under the age of 40?"
                nameOfFormControl="patronAgeCheckUnder40"
              >
              </app-form-field-radio>

              <!-- enable/disable based on answer to patron age check under 40 -->
              <app-form-field-radio
                inputId="quote-liquorLiabililityIdentificationCheckedElectronically"
                [form]="
                  form.get('liabilityCoverages.eligibilityUWQuestions.liquorLiabilityUWQuestions')
                "
                [submitted]="submitted()"
                questionText="Is identification checked via an electronic scanner?"
                nameOfFormControl="identificationCheckedElectronically"
              >
              </app-form-field-radio>

              <!-- confirm this should start blank and not w/ 0 -->
              <app-form-field-text
                inputId="quote-liquorLiabililityLicenseYearsExperience"
                [form]="
                  form.get('liabilityCoverages.eligibilityUWQuestions.liquorLiabilityUWQuestions')
                "
                [submitted]="submitted()"
                labelText="How many years of experience does management have managing a liquor-serving establishment?"
                nameOfFormControl="yearsOfLiquorExperience"
                appDelimitedNumberMask
              >
              </app-form-field-text>

              <app-form-field-radio
                inputId="quote-liquorLiabililityRequireAlcoholTraining"
                [form]="
                  form.get('liabilityCoverages.eligibilityUWQuestions.liquorLiabilityUWQuestions')
                "
                [submitted]="submitted()"
                questionText="Do you require alcohol training?"
                nameOfFormControl="requireAlcoholTraining"
              >
              </app-form-field-radio>

              <app-form-field-text
                inputId="quote-liquorLiabililityYearsLicenseIssuedFor"
                [form]="
                  form.get('liabilityCoverages.eligibilityUWQuestions.liquorLiabilityUWQuestions')
                "
                [submitted]="submitted()"
                labelText="How many years has the license been issued for?"
                nameOfFormControl="yearsLicenseIssuedFor"
                appDelimitedNumberMask
              >
              </app-form-field-text>

              <app-form-field-radio
                inputId="quote-liquorLiabililitySalesOrLowerPrice"
                [form]="
                  form.get('liabilityCoverages.eligibilityUWQuestions.liquorLiabilityUWQuestions')
                "
                [submitted]="submitted()"
                questionText="Does the establishment run any sales of or at any time lower the price of any of their liquor?"
                nameOfFormControl="liquorSalesOrLowerPrice"
                tooltipText="Examples include but are not limited to happy hours, drink of the hour/day/week specials, celebration of national holidays, etc."
              >
              </app-form-field-radio>
            </div>

            <!-- BOPv1 Contractors -->
            <app-form-field-radio
              inputId="quote-limitPerPersonOfMedicalExpenses"
              [form]="form.get('liabilityCoverages')"
              [submitted]="submitted()"
              questionText="Medical Expenses"
              nameOfFormControl="limitPerPersonOfMedicalExpenses"
              [options]="availableLimitsPerPersonOfMedicalExpenses"
              tooltipText="Coverage does not require a finding of legal liability and is available to cover
            medical expenses incurred within a specified period by a claimant for a covered injury,
            regardless of whether the insured was at fault."
            >
            </app-form-field-radio>

            <app-form-field-slider
              inputId="quote-damageToPremises"
              [form]="form.get('liabilityCoverages')"
              nameOfFormControl="damageToPremises"
              min="50000"
              max="1000000"
              step="50000"
              labelText="Damage to premises rented to you"
              isCurrencyValue="true"
            >
            </app-form-field-slider>

            <app-employee-benefits-liability-coverage
              id="quote-employeeBenefitsCoverageSection"
              [form]="form.get('liabilityCoverages')"
              [submitted]="submitted()"
              [baseState]="baseState()"
            >
            </app-employee-benefits-liability-coverage>

            <div
              class="helper-card-container"
              *ngIf="form.get('liabilityCoverages.stopGap').enabled"
            >
              <app-form-field-radio
                inputId="quote-stopGap"
                [form]="form.get('liabilityCoverages')"
                nameOfFormControl="stopGap"
                [submitted]="submitted()"
                questionText="Include Stop Gap Employers Liability?"
              ></app-form-field-radio>

              <div class="helper-card">
                <div class="pill-tag pill-tag__main">Note</div>
                <p>The Stop Gap Limits will be set to match the General Liability limits</p>
              </div>
            </div>

            <!-- UW requested this question to be presented on the coverages page. -->
            <app-form-field-radio
              inputId="quote-employeeHandbook"
              [form]="form.get('liabilityCoverages')"
              nameOfFormControl="employeeHandbook"
              [submitted]="submitted()"
              questionText="Does the insured have a written employee handbook?"
            >
            </app-form-field-radio>

            <!-- UW requested this question to be presented on the coverages page. -->
            <app-form-field-radio
              inputId="quote-janitorialServices"
              [form]="form.get('liabilityCoverages')"
              nameOfFormControl="janitorialServices"
              [submitted]="submitted()"
              questionText="Are janitorial services contracted out to a third party?"
            >
            </app-form-field-radio>

            <ng-container *ngIf="showOperationsSpecificCoverageSection()">
              <h3 class="question-subsection-header">Operations Specific Optional Coverages</h3>

              <!-- BOPv1 Contractors -->
              <app-form-field-radio
                inputId="quote-acceptSnowPlowCompletedOpsCoverage"
                [form]="form.get('liabilityCoverages')"
                [submitted]="submitted()"
                cssClass="snow-plow-coverage"
                questionText="Snow Plow Products/Completed Ops"
                nameOfFormControl="acceptSnowPlowCompletedOpsCoverage"
                tooltipText="This endorsement adds back coverage (ordinarily excluded)
              when the business uses an “auto” for snow or ice removal operations"
              >
              </app-form-field-radio>

              <app-form-field-select
                inputId="quote-eachCoveredJobSiteLimit"
                [form]="form.get('liabilityCoverages')"
                [submitted]="submitted()"
                labelText="Each Covered Job Site Limit"
                nameOfFormControl="eachCoveredJobSiteLimit"
                [availableOptions]="availableEachCoveredJobSiteLimits"
                tooltipText="Examples of property to include in this limit include owned supplies, equipment,
              machinery, or in your care, custody or control, and which are to be installed by you or at
              the insured’s direction.  It also includes items such as any temporary structures built or
              assembled on-site, scaffolding, and construction forms. The property at all covered job sites
              combined is automatically provided at 3x the property of the ‘each covered job site limit’ selected."
              >
              </app-form-field-select>

              <app-form-field-text
                inputId="quote-propertyInTransitLimit"
                [form]="form.get('liabilityCoverages')"
                [submitted]="submitted()"
                labelText="Property in Transit"
                nameOfFormControl="propertyInTransitLimit"
                tooltipText="Coverage extends to include contractors' materials, supplies, equipment,
              machinery, fixtures and temporary structures while in transit (not at the insured’s
              location nor at the customer’s location)."
                appMoneyMask="WITHOUT_DECIMAL"
              >
              </app-form-field-text>
              <!-- End BOPv1 Contractors -->

              <!-- BOPv2 Contractors -->
              <app-form-field-radio
                inputId="quote-installationLimit"
                [form]="form.get('liabilityCoverages')"
                nameOfFormControl="installationLimit"
                [options]="availableInstallationLimits"
                questionText="Contractors’ Installation Coverage Limit"
              >
              </app-form-field-radio>

              <app-form-field-radio
                inputId="quote-toolsBlanketLimit"
                [form]="form.get('liabilityCoverages')"
                nameOfFormControl="toolsBlanketLimit"
                [options]="availableToolsBlanketLimits"
                questionText="Contractors’ Tools and Equipment - Blanket Limit"
              >
              </app-form-field-radio>

              <app-form-field-radio
                inputId="quote-toolsPerItemLimit"
                [form]="form.get('liabilityCoverages')"
                nameOfFormControl="toolsPerItemLimit"
                [submitted]="submitted()"
                [options]="availableToolsSubLimits"
                questionText="Contractors’ Tools and Equipment - Per Item Sublimit"
              >
              </app-form-field-radio>

              <!-- UW requested this question to be presented on the coverages page. -->
              <app-form-field-radio
                inputId="quote-equipmentLeftInVehicle"
                [form]="form.get('liabilityCoverages')"
                nameOfFormControl="equipmentLeftInVehicle"
                [submitted]="submitted()"
                questionText="Are tools and equipment ever left in a vehicle?"
              >
              </app-form-field-radio>

              <!-- UW requested this question to be presented on the coverages page. -->
              <app-form-field-radio
                inputId="quote-stressTestsWaterLines"
                [form]="form.get('liabilityCoverages')"
                nameOfFormControl="stressTestsWaterLines"
                [submitted]="submitted()"
                questionText="Does the insured stress test all water lines on all jobs prior to completing work?"
              >
              </app-form-field-radio>

              <!-- End BOPv2 Contractors -->

              <app-form-field-radio
                inputId="quote-opticalAndHearingAidEstablishmentsProfessionalLiability"
                [form]="form.get('liabilityCoverages')"
                [submitted]="submitted()"
                questionText="Optical and Hearing Aid Establishments Professional Liability"
                nameOfFormControl="opticalAndHearingAidEstablishmentsProfessionalLiability"
              >
              </app-form-field-radio>
              <app-form-field-text
                inputId="quote-opticalAndHearingAidSales"
                [form]="form.get('liabilityCoverages')"
                [submitted]="submitted()"
                labelText="Optical and Hearing Aid Sales"
                nameOfFormControl="opticalAndHearingAidSales"
                appMoneyMask="WITHOUT_DECIMAL"
              >
              </app-form-field-text>

              <app-form-field-radio
                inputId="quote-printersErrorsAndOmissionsProfessionalLiability"
                [form]="form.get('liabilityCoverages')"
                [submitted]="submitted()"
                questionText="Printers Errors and Omissions Professional Liability"
                nameOfFormControl="printersErrorsAndOmissionsProfessionalLiability"
              >
              </app-form-field-radio>
              <app-form-field-text
                inputId="quote-printersErrorsAndOmissionsSalesOrPayroll"
                [form]="form.get('liabilityCoverages')"
                [submitted]="submitted()"
                [labelText]="labelTextForPrintersErrorsAndOmissionsSalesOrPayroll()"
                nameOfFormControl="printersErrorsAndOmissionsSalesOrPayroll"
                placeholder="$"
                appMoneyMask="WITHOUT_DECIMAL"
              >
              </app-form-field-text>

              <app-form-field-radio
                inputId="quote-funeralDirectorsProfessionalLiability"
                [form]="form.get('liabilityCoverages')"
                [submitted]="submitted()"
                questionText="Funeral Directors Professional Liability"
                nameOfFormControl="funeralDirectorsProfessionalLiability"
              >
              </app-form-field-radio>

              <app-form-field-radio
                inputId="quote-barberShopsAndHairSalonsProfessionalLiability"
                [form]="form.get('liabilityCoverages')"
                [submitted]="submitted()"
                questionText="Barber Shops and Hair Salons Professional Liability"
                nameOfFormControl="barberShopsAndHairSalonsProfessionalLiability"
              >
              </app-form-field-radio>
              <app-form-field-text
                inputId="quote-barberShopsNumberOfOperators"
                [form]="form.get('liabilityCoverages')"
                [submitted]="submitted()"
                labelText="Number of Operators"
                cssClass="form-subsection"
                nameOfFormControl="barberShopsNumberOfOperators"
                appDelimitedNumberMask
              >
              </app-form-field-text>

              <app-form-field-radio
                inputId="quote-beautySalonsProfessionalLiability"
                [form]="form.get('liabilityCoverages')"
                [submitted]="submitted()"
                questionText="Beauty Salons Professional Liability"
                nameOfFormControl="beautySalonsProfessionalLiability"
              >
              </app-form-field-radio>
              <app-form-field-text
                inputId="quote-beautySalonsNumberOfOperators"
                [form]="form.get('liabilityCoverages')"
                [submitted]="submitted()"
                labelText="Number of Operators"
                cssClass="form-subsection"
                nameOfFormControl="beautySalonsNumberOfOperators"
                appDelimitedNumberMask
              >
              </app-form-field-text>

              <app-form-field-radio
                inputId="quote-veterinariansProfessionalLiability"
                [form]="form.get('liabilityCoverages')"
                [submitted]="submitted()"
                questionText="Veterinarians Professional Liability"
                nameOfFormControl="veterinariansProfessionalLiability"
              >
              </app-form-field-radio>

              <div class="form-subsection">
                <app-form-field-text
                  inputId="quote-numberOfVeterinarians"
                  [form]="form.get('liabilityCoverages')"
                  [submitted]="submitted()"
                  labelText="Number of Veterinarians"
                  nameOfFormControl="numberOfVeterinarians"
                  appDelimitedNumberMask
                >
                </app-form-field-text>
                <app-form-field-radio
                  inputId="quote-veterinariansOnlyTreatHousePets"
                  [form]="form.get('liabilityCoverages')"
                  [submitted]="submitted()"
                  questionText="Do the veterinarians only specialize in the treatment of house pets?"
                  nameOfFormControl="veterinariansOnlyTreatHousePets"
                >
                </app-form-field-radio>
              </div>
            </ng-container>

            <div
              class="helper-card-container"
              *ngIf="form.get('liabilityCoverages.acceptHiredNonOwnedAutoCoverage').enabled"
            >
              <div>
                <app-form-field-radio
                  inputId="quote-acceptHNOA"
                  cssClass="js-hnoa-checkbox"
                  questionText="Include Hired Non-Owned Auto Coverage?"
                  nameOfFormControl="acceptHiredNonOwnedAutoCoverage"
                  [form]="form.get('liabilityCoverages')"
                  tooltipText=" When the business operator uses autos that are not owned, leased, hired, or borrowed
                by the business entity, they need Hired and Non-owned Auto Liability (HNOA). If the business owns one
                or more vehicles, HNOA coverage must be purchased through a commercial auto policy and not selected
                on this BOP product."
                >
                </app-form-field-radio>
                <div
                  class="form-subsection"
                  *ngIf="form.get('liabilityCoverages.hnoaNumberOfDrivers').enabled"
                >
                  <app-form-field-text
                    inputId="quote-hnoaNumberOfDrivers"
                    [form]="form.get('liabilityCoverages')"
                    [submitted]="submitted()"
                    labelText="Number of Drivers"
                    nameOfFormControl="hnoaNumberOfDrivers"
                    appDelimitedNumberMask
                  >
                  </app-form-field-text>
                  <app-form-field-radio
                    inputId="quote-hnoaOver18"
                    cssClass="js-hnoa-checkbox"
                    questionText="Are all employees with responsibilities for delivering food over 18 years of age?"
                    nameOfFormControl="hnoaOver18"
                    [form]="form.get('liabilityCoverages')"
                  >
                  </app-form-field-radio>
                  <app-form-field-radio
                    inputId="quote-hnoaCertificateOfInsurance"
                    cssClass="js-hnoa-checkbox"
                    questionText="Please confirm that the insured requests and retains for no less than one calendar year a Certificate of Insurance confirming limits of a $1M CSL from all employees with driving responsibilities"
                    nameOfFormControl="hnoaCertificateOfInsurance"
                    [form]="form.get('liabilityCoverages')"
                  >
                  </app-form-field-radio>
                  <app-form-field-radio
                    inputId="quote-hnoa3rdPartyDelivery"
                    cssClass="js-hnoa-checkbox"
                    questionText="Please confirm that the insured does not use any third-party delivery services (EG: GrubHub, Postmates, UberEats, or similar services)"
                    nameOfFormControl="hnoa3rdPartyDelivery"
                    [form]="form.get('liabilityCoverages')"
                  >
                  </app-form-field-radio>
                  <app-form-field-radio
                    inputId="quote-hnoaMotorVehicles"
                    cssClass="js-hnoa-checkbox"
                    questionText="Does the insured review Motor Vehicle Records on an annual basis for all employees with driving responsibilities, and confirms that there are no more than 2 minor violations and 0 major violations?"
                    nameOfFormControl="hnoaMotorVehicles"
                    [form]="form.get('liabilityCoverages')"
                  >
                  </app-form-field-radio>
                  <app-form-field-radio
                    inputId="quote-hnoaLocationRadius"
                    cssClass="js-hnoa-checkbox"
                    questionText="Please confirm that deliveries are consistent with the hours of operation and are limited to a 25 mile radius from the insured’s location."
                    nameOfFormControl="hnoaLocationRadius"
                    [form]="form.get('liabilityCoverages')"
                  >
                  </app-form-field-radio>
                </div>
              </div>

              <div class="helper-card">
                <div class="pill-tag pill-tag__main">Reminder</div>
                <p>
                  Hired Non-Owned Auto Coverage is not available if the operation does delivery.
                </p>
              </div>
            </div>

            <app-eligibility-uw-questions
              [form]="form.get('liabilityCoverages.eligibilityUWQuestions')"
              [submitted]="submitted()"
            >
            </app-eligibility-uw-questions>
          </section>

          <section *ngIf="isCurrentStep('additional-coverages')" class="form-animation">
            <h1 class="form-header-below-link">Additional Coverages</h1>

            <app-form-field-select
              inputId="quote-limitForEmployeeDishonesty"
              [form]="form.get('additionalCoverages')"
              [submitted]="submitted()"
              labelText="Employee Dishonesty Limit"
              nameOfFormControl="limitForEmployeeDishonesty"
              [availableOptions]="availableLimitsForEmployeeDishonesty"
              placeholder="$0"
              tooltipText="Employee Dishonesty coverage insures against theft of the insured’s physical property by its own employees."
            >
            </app-form-field-select>

            <app-employment-practices-liability
              [bopVersion]="form.get('policyInfo.bopVersion').value"
              [epliV2Options]="formService.epliOptions"
              [form]="form.get('additionalCoverages.employmentRelatedPracticesLiabilityCoverage')"
              [submitted]="submitted()"
            >
            </app-employment-practices-liability>

            <app-form-field-radio
              *ngIf="isTerrorismCoverageOptional()"
              inputId="quote-acceptCertifiedActsOfTerrorismCoverage"
              [form]="form.get('additionalCoverages')"
              [submitted]="submitted()"
              questionText="Include Certified Acts of Terror coverage?"
              nameOfFormControl="acceptCertifiedActsOfTerrorismCoverage"
            >
            </app-form-field-radio>

            <div
              class="helper-card-container"
              *ngIf="
                !cyberParentId &&
                !isSampleQuote &&
                form.get('additionalCoverages.cyberLiabilityCoverage.selectedCyberCoverage').enabled
              "
            >
              <app-attune-bop-cyber-liability-coverage
                [accountId]="accountId"
                [domain]="insAccount$.getValue().website"
                [baseState]="form.get('policyInfo.baseState').value"
                [bopVersion]="form.get('policyInfo.bopVersion').value"
                [form]="form.get('additionalCoverages.cyberLiabilityCoverage')"
                [submitted]="submitted()"
                [coalitionCyberMappedIndustries]="cyberNaicsMappings"
                [activeCyberPrice]="activeCyberPrice"
                [endorsementPrice]="endorsementPrice"
                (openPriceComparisonModal)="openCyberPriceComparisonModal()"
              >
              </app-attune-bop-cyber-liability-coverage>
              <div class="helper-card helper-card__no-margin">
                <div class="pill-tag pill-tag__main">Note</div>
                <p>
                  Selecting the Active Cyber product will generate a Coalition quote when submitting
                  this BOP+ quote. The Coalition product includes broad coverage, configurable
                  limits, and advanced risk screening.
                </p>
                <a
                  (click)="openCyberPriceComparisonModal()"
                  (keyup.enter)="openCyberPriceComparisonModal()"
                >
                  Explore coverage
                </a>
              </div>
            </div>

            <h3 class="question-subsection-header">Additional Insureds</h3>
            <div class="js-additional-insureds-section">
              <app-form-field-radio
                [form]="form.get('additionalCoverages')"
                inputId="quote-hasAdditionalInsuredBusinesses"
                [submitted]="submitted()"
                questionText="Would you like to add an additional insured?"
                nameOfFormControl="hasAdditionalInsuredBusinesses"
              >
              </app-form-field-radio>

              <app-additional-insureds
                *ngIf="form.value.additionalCoverages.hasAdditionalInsuredBusinesses"
                prefix="policy"
                [additionalInsureds]="form.get('additionalCoverages.additionalInsuredBusinesses')"
                [locationAddresses]="form.get('policyInfo.locationAddresses').value"
                [submitted]="submitted()"
                (addInsuredBusiness)="
                  formService.addAdditionalInsuredBusiness(
                    form.get('additionalCoverages.additionalInsuredBusinesses')
                  )
                "
                (removeInsuredBusiness)="
                  formService.removeAdditionalInsuredBusinessAt(
                    $event,
                    form.get('additionalCoverages.additionalInsuredBusinesses')
                  )
                "
              >
              </app-additional-insureds>
            </div>

            <h3 class="question-subsection-header">Waiver of Subrogation</h3>
            <div class="js-waiver-subrogation-section">
              <app-form-field-radio
                [form]="form.get('additionalCoverages')"
                inputId="quote-hasWaiversOfSubrogation"
                [submitted]="submitted()"
                questionText="Would you like to add a waiver of subrogation?"
                nameOfFormControl="hasWaiversOfSubrogation"
                [disabled]="waiverOfSubrogationReadOnly()"
              >
              </app-form-field-radio>

              <app-waiver-of-subrogation
                *ngIf="form.get('additionalCoverages.waiversOfSubrogation').enabled"
                prefix="policy"
                [waiversOfSubrogation]="form.get('additionalCoverages.waiversOfSubrogation')"
                [submitted]="submitted()"
                (addWaiver)="
                  formService.addWaiverOfSubrogation(
                    form.get('additionalCoverages.waiversOfSubrogation')
                  )
                "
                (removeWaiver)="
                  formService.removeWaiverOfSubrogationAt(
                    $event,
                    form.get('additionalCoverages.waiversOfSubrogation')
                  )
                "
              >
              </app-waiver-of-subrogation>
            </div>
          </section>

          <section *ngIf="isCurrentStep('excess-liability')" class="form-animation">
            <h1 class="form-header-below-link">Core Specialty Excess Liability Submission</h1>

            <app-dialog-box
              type="primary"
              class="form-field js-manual-excess-dialog"
              id="manualExcessInfoDialog"
            >
              Please enter the information below to begin an Excess Liability submission. Excess
              Liability quoting, binding, and servicing will be handled by our team, and will not be
              visible in the agent portal.
              <div class="dialog-text-links">
                <a
                  class="dialog-text-link"
                  href="https://blog.attuneinsurance.com/were-migrating-aig-xs-policies-to-core-specialty"
                  target="_blank"
                  data-amplitude-input-name="request_manual_excess_info_link"
                >
                  See more
                </a>
              </div>
            </app-dialog-box>

            <app-uw-questions
              [uwQuestionsFormGroup]="form.get('excessLiability.uwQuestions')"
              [uwQuestions]="formService.uwQuestions"
              (answersAreValid)="setUWQuestionsHaveValidAnswers($event)"
            >
            </app-uw-questions>
            <app-excess-liability
              *ngIf="uwQuestionsHaveValidAnswers"
              [excessLiabilityFormGroup]="form.get('excessLiability')"
              [excessLiabiltyQuotable$]="excessLiabiltyQuotable$"
              [baseState]="baseState()"
              isEditing="isEditing"
              [formService]="formService"
              [submitted]="submitted()"
            >
            </app-excess-liability>
          </section>

          <div class="form-footer">
            <div class="validation" *ngIf="submitted() && !isCurrentStepValid()">
              <div class="validation-message">
                {{ getValidationMessage() }}
              </div>
            </div>
            <div class="nav-button-group nav-button-group__left">
              <button
                *ngIf="!isFinalStep()"
                id="quote-form-next-button"
                [ngClass]="{
                  button: true,
                  'js-step-forward-button': true,
                  button__discouraged: !this.isCurrentStepValid()
                }"
                type="submit"
                class="button__primary"
                [disabled]="
                  uwDecisionLoading || buildingVerificationLoading || eligibilityCheckLoading
                "
              >
                <ng-container *ngIf="uwDecisionLoading || buildingVerificationLoading">
                  <div class="button-loader">
                    <svg class="circle-spinner">
                      <circle
                        cx="11"
                        cy="11"
                        r="9"
                        stroke-width="2"
                        stroke-dasharray="0,1000"
                        d="M5 40 l215 0"
                        fill="transparent"
                      />
                    </svg>
                  </div>
                  Pulling location info...
                </ng-container>
                <ng-container *ngIf="!uwDecisionLoading && !buildingVerificationLoading"
                  >Next</ng-container
                >
              </button>
              <div *ngIf="isFinalStep() && !isNavigating()">
                <button
                  id="quote-form-submit-button"
                  [ngClass]="{
                    button: true,
                    'js-step-forward-button': true,
                    button__discouraged: !this.isCurrentStepValid()
                  }"
                  type="submit"
                  class="button__confirm"
                  [disabled]="isQuoteInProgress() || eligibilityCheckLoading"
                >
                  <span *ngIf="isQuoteInProgress()">{{
                    isEditing || shouldSaveDraftForPrefillOutage ? 'Saving...' : 'Submitting...'
                  }}</span>
                  <span *ngIf="shouldSaveDraftForPrefillOutage && !isQuoteInProgress()"
                    >Save as Draft</span
                  >
                  <span *ngIf="!shouldSaveDraftForPrefillOutage && !isQuoteInProgress()"
                    >Submit</span
                  >
                </button>
                <p
                  *ngIf="bopVersion() === 2 || meToo()"
                  class="small-text gray-text fraud-language"
                >
                  {{ getFraudLanguage() }}
                </p>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </ng-container>

    <ng-container *ngIf="isDevMode">
      <app-quote-form-debugger
        [submitted]="submitted()"
        [insuredAccount]="insAccount$.getValue()"
        [form]="form"
        [now]="now"
        [tsRequestId]="tsRequestId"
        [currentStep]="currentStep"
      ></app-quote-form-debugger>
    </ng-container>
  </div>
  <div class="app-page-form-side-rail"></div>
</div>
<app-quote-error-modal
  [open]="quoteResultModalOpen"
  (closeQuoteErrorModal)="handleQuoteResultModalClose($event)"
  [errors]="getErrorList()"
  [errorType]="getErrorType(bopVersion())"
></app-quote-error-modal>

<app-modal [open]="quoteOverrideDeclineModalOpen" (close)="handleQuoteOverrideModalClose()">
  <div class="modal-image" role="presentation">
    <img src="/assets/img/declined.png" alt="Declined quote" />
  </div>
  <h2>Quote Under Review</h2>
  <p>
    This quote is under review; our team will reach out in 24-48 hours. If you have questions or
    concerns, please reach out to your account manager or ADPhelp&#64;attuneinsurance.com
  </p>
  <button
    (click)="handleQuoteOverrideModalClose()"
    class="button button__primary button__no-margin"
  >
    Back to accounts
  </button>
</app-modal>

<app-modal
  [open]="displayWindDeclineModal && !shouldSaveDraftForPrefillOutage"
  (handleModalClose)="closeWindDeclineModal()"
>
  <div class="modal-image" role="presentation">
    <img src="/assets/img/declined.png" alt="Declined quote" />
  </div>
  <h2>Quote Declined</h2>
  <p>
    This location is ineligible for wind coverage, and wind coverage is required in
    {{ windDeclineModalState }}.
  </p>
  <button (click)="closeWindDeclineModal()" class="button">Back to accounts</button>
</app-modal>

<app-modal
  [open]="displayNewOrOverwriteQuoteModal"
  [modalClass]="'app-modal-content__left-align'"
  (handleModalClose)="closeNewOrOverwriteQuoteModal()"
>
  <h2>Confirm Edit</h2>
  <p>Would you like to overwrite this quote or create a new quote?</p>
  <div class="nav-button-group">
    <button
      (click)="createNewQuoteWhileEditing()"
      class="button button__secondary button__no-margin"
    >
      Create new quote
    </button>
    <button (click)="overwriteQuoteWhileEditing()" class="button button__primary button__no-margin">
      Overwrite this quote
    </button>
  </div>
</app-modal>
<app-modal
  [modalClass]="'app-modal-content__left-align'"
  [open]="displayGuidelinesRecheckModal"
  (handleModalClose)="closeGuidelinesRecheckModalAndStay()"
>
  <main *ngIf="loading" class="placeholder-form-container">
    <div class="placeholder-h1"></div>
    <div class="placeholder-form-label"></div>
    <div class="placeholder-form-input"></div>
    <div class="placeholder-form-label"></div>
    <div class="placeholder-form-input"></div>
    <div class="placeholder-form-label"></div>
    <div class="placeholder-form-input"></div>
    <div class="placeholder-form-label"></div>
    <div class="placeholder-form-input"></div>
    <div class="placeholder-form-submit"></div>
  </main>
  <div *ngIf="!loading">
    <h2>Please review the following guidelines in {{ baseState() }}.</h2>
    <ul class="guideline-list">
      <li *ngFor="let guideline of guidelinesData">
        {{ guideline }}
      </li>
    </ul>
    <div
      class="additional-guidelines-list"
      *ngIf="additionalGuidelines && additionalGuidelines.length"
    >
      <h3>Recently updated:</h3>
      <ul class="guideline-list guideline-list__no-margin">
        <li *ngFor="let newGuideline of additionalGuidelines">
          {{ newGuideline }}
        </li>
      </ul>
    </div>
    <app-form-field-checkbox
      inputId="accept-guidelines"
      labelText="I have read and confirm that the business follows these guidelines"
      nameOfFormControl="guidelines"
      [submitted]="guidelinesRecheckError"
      [invalid]="guidelinesRecheckError"
      [form]="form"
    >
    </app-form-field-checkbox>
    <div class="nav-button-group">
      <button
        (click)="closeGuidelinesRecheckModalAndStay()"
        class="button button__secondary button__no-margin"
      >
        Cancel
      </button>
      <button
        (click)="closeGuidelinesRecheckModalAndProceed()"
        class="button button__primary button__no-margin"
      >
        Continue
      </button>
    </div>
  </div>
</app-modal>
<app-modal
  [open]="displayMixedCarrierDeclineModal"
  (handleModalClose)="closeMixedCarrierDeclineModal()"
>
  <div class="modal-image" role="presentation">
    <img src="/assets/img/declined.png" alt="Declined quote" />
  </div>
  <h2>Quote Declined</h2>
  <p>
    Locations in the state of New York may not be included on multi-state policies at this time.
    Feel free to contact our Customer Care Team if you have any questions.
  </p>
  <button (click)="closeMixedCarrierDeclineModal()" class="button button__primary">
    Back to accounts
  </button>
</app-modal>
<app-modal [open]="displayBopCatDeclineModal" (handleModalClose)="closeBopCatDeclineModal()">
  <div class="modal-image" role="presentation">
    <img src="/assets/img/declined.png" alt="Declined quote" />
  </div>
  <h2>Quote Declined</h2>
  <p>
    Quotes with this base state are ineligible for BOP at this time. Feel free to contact our
    Customer Care Team if you have any questions.
  </p>
  <button (click)="closeBopCatDeclineModal()" class="button button__primary">
    Back to accounts
  </button>
</app-modal>
<app-modal
  [open]="displayClassCodeIneligibleModal"
  (handleModalClose)="closeClassCodeIneligbleModal()"
>
  <div class="modal-image" role="presentation">
    <img src="/assets/img/ineligible.png" alt="Declined quote" />
  </div>
  <h2>Ineligible location</h2>
  <p>
    Class code "{{ classCode }}" is ineligible for {{ baseState() }}. Here's a
    <a
      href="https://s3.us-east-2.amazonaws.com/myattune-web-assets/public/documents/Attune+BOP+UW+Guidelines.pdf"
      target="_blank"
      >link to our guidelines</a
    >
    to learn more. Feel free to contact our Customer Care Team if you have any questions.
  </p>
  <button (click)="closeClassCodeIneligbleModal()" class="button button__primary">
    Back to accounts
  </button>
</app-modal>

<ng-container *ngIf="lroFirstTimeModalOpen">
  <app-modal
    [open]="lroFirstTimeModalOpen"
    (handleModalClose)="toggleFirstTimeLROModal()"
    modalNameForAmplitudeEvent="bop-first-time-lro-step"
    modalClass="app-modal-content__left-align app-modal-content__first-lro-video"
  >
    <div class="pill-tag pill-tag__main-inverted">New feature</div>
    <h2 class="modal-header-with-tag">Improved LRO quoting</h2>
    <p>
      We've put together a short video to explain everything you need to know about quoting LROs
      with Attune. Happy quoting!
    </p>

    <section class="offset-border-group">
      <iframe
        [ngStyle]="{ width: '540px', height: '325px' }"
        src="https://www.youtube.com/embed/bY5LZh_dW-E"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <h3>Watch Attune's short guide to quoting LROs</h3>
    </section>
  </app-modal>
</ng-container>
<app-modal
  [open]="showWCQuotePrompt"
  [modalContainerClass]="'wc-prompt-modal'"
  [modalClass]="'app-modal-content__left-align'"
  (handleModalClose)="closeWCQuotePrompt()"
  [modalNameForAmplitudeEvent]="'wc-cross-sell-prompt'"
>
  <h2>While we work on this quote...</h2>
  <p>You're 30% of the way through a Workers' Comp quote with EMPLOYERS!</p>
  <div class="modal-action-group">
    <app-form-field-checkbox
      inputId="dontShowAgain"
      labelText="Don't show me again"
      nameOfFormControl="dontShowAgain"
      [form]="wcPromptForm"
    ></app-form-field-checkbox>
    <div class="nav-button-group">
      <button
        (click)="closeWCQuotePrompt()"
        data-amplitude-input-name="no-thanks-wc-cross-sell-button-clicked"
        class="button button__secondary button__no-margin"
      >
        No thanks
      </button>
      <button
        (click)="startWCQuote()"
        data-amplitude-input-name="start-wc-quote-cross-sell-button-clicked"
        class="button button__primary button__no-margin"
      >
        Complete WC quote
      </button>
    </div>
  </div>
</app-modal>
<app-modal
  [open]="displayCyberPriceComparisonModal"
  (handleModalClose)="closeCyberPriceComparisonModal()"
  modalClass="app-modal-content__left-align"
  [modalNameForAmplitudeEvent]="getSelectedCyberOption()"
>
  <h2>Price & Coverage Comparison</h2>
  <app-attune-bop-cyber-price-comparison-table
    [activeCyberPrice]="activeCyberPrice"
    [endorsementPrice]="endorsementPrice"
  ></app-attune-bop-cyber-price-comparison-table>
  <p *ngIf="!!activeCyberPrice || !!endorsementPrice" class="mt-1 p__gray p__small-text">
    *These prices are subject to change. Please submit your quote to see an accurate price.
  </p>
</app-modal>
<app-modal
  [open]="displayHnoaIneligibleModal"
  (handleModalClose)="closeHnoaIneligibleModal()"
  modalClass="app-modal-content__left-align"
>
  <h2>Hired Non-Owned Auto Coverage Ineligible</h2>
  <p>
    This account is not eligible for Hired Non-Owned Auto Coverage. The coverage has been removed
    from the quote.
  </p>
  <div class="nav-button-group">
    <button (click)="closeHnoaIneligibleModal()" class="button button__primary button__no-margin">
      Continue
    </button>
  </div>
</app-modal>
<app-attune-bop-estimated-replacement-cost-modal
  [open]="displayEstimatedReplacementCostModal"
  [interventionAlerts]="uwInterventionAlerts"
  (update)="reviseBuildingLimitFromEstimate($event)"
>
</app-attune-bop-estimated-replacement-cost-modal>

<div class="quoting-game-container" *ngIf="isQuoteInProgress()">
  <app-quoting-game
    [finish$]="getQuoteSuccessSubject()"
    [showTip]="isSampleQuote"
  ></app-quoting-game>
</div>
