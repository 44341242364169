import { Injectable, OnDestroy } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormArray,
  UntypedFormControl,
  ValidationErrors,
  AbstractControl,
  FormControl,
} from '@angular/forms';
import {
  of as observableOf,
  merge as observableMerge,
  combineLatest,
  distinctUntilChanged,
  BehaviorSubject,
  Observable,
  Subject,
} from 'rxjs';
import { startWith, map, filter } from 'rxjs/operators';
import * as moment from 'moment';
import {
  entries,
  get as lodashGet,
  flatMap,
  omit,
  pick,
  some,
  times,
  uniq,
  values,
  isEqual,
} from 'lodash';
import {
  clampControl,
  enableDisableControl,
  getControl,
  minDateExceededValidator,
  rangeValidator,
  getAddress,
  patchControl,
  maxDateExceededValidator,
  validatorWithMessage,
  getFormGroup,
  validateCurrencyGreaterThanZero,
  validateCurrencyGreaterThanValue,
  requireCheckboxesToBeCheckedValidator,
  percentValidator,
} from 'app/shared/helpers/form-helpers';
import { US_DATE_MASK } from 'app/constants';
import {
  FormDslSteppedFormBaseService,
  RouteFormStep,
} from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';
import {
  AVAILABLE_BUSINESS_TYPES,
  BOP_V2_EPLI_INELIGIBLE_STATES,
  EpliOptions,
  EPLI_EXCEPTIONS,
  INELIGIBLE_STATES,
  HNOA_NOT_AVAILABLE_BUSINESS_TYPES,
  HNOA_AVAILABLE_STATES,
  HNOA_AVAILABLE_CLASS_CODES,
  BUILDING_LIMIT_NOT_AVAILABLE_BUSINESS_TYPES,
  LIQUOR_LIABILITY_COVERAGE_EXCLUSION_STATES,
  QuestionCode,
  UW_QUESTIONS,
  UWQuestion,
  REQUIRED_UW_QUESTIONS_BY_BUILDING_CODE,
  AVAILABLE_LIABILITIES_BY_BUILDING_CLASSIFICATION,
  OperationsCoverage,
  ALL_PROF_LIABILITIES,
  PROF_LIABILITY_FIELD_NAMES,
  BOP_V2_CYBER_OPTIONS,
  BOP_V2_EPLI_AGG_LIMITS_HIGH,
  BOP_V2_EPLI_AGG_LIMITS_NORMAL,
  BOP_V2_EPLI_SPLIT_LIMITS_NORMAL,
  BOP_V2_EPLI_SPLIT_LIMITS_HIGH,
  BOP_V2_EPLI_DEFAULT_OPTIONS,
  BOP_V2_EPLI_DEFENSE_LIMIT_OPTIONS_HIGH,
  BOP_V2_EPLI_DEFENSE_LIMIT_OPTIONS,
  BOP_V2_EPLI_AGG_LIMIT_OPTIONS_HIGH,
  BOP_V2_EPLI_AGG_LIMIT_OPTIONS,
  BOP_V2_DEDUCTIBLE_OPTIONS,
  BOP_V2_EPLI_SPLIT_STATES,
  BOP_V2_STATES_WITHOUT_2500_EPLI_DEDUCTIBLE,
  BOP_V2_CYBER_SPLIT_STATES,
  EACH_COVERED_JOB_SITE_LIMIT_DEFAULT,
  EXCLUDED_V2_CYBER_CODES,
  KEEP_V1_CONTRACTOR_CONTROL_STATES,
  SMALL_ACCOUNT_SQUARE_FOOTAGE_MAXIMUM,
  CyberProductType,
  STOP_GAP_PER_OCCURENCE_THRESHOLD,
  INCLUDED_STOP_GAP_STATES,
  EXCESS_LIABILITY_PER_OCCURRENCE_THRESHOLD,
  EXCLUDED_V2_EXCESS_STATES,
  EXCLUDED_V2_EXCESS_CODES,
  BARBER_SHOP_CLASS_CODE,
  NAIL_SALON_CLASS_CODE,
} from 'app/features/attune-bop/models/constants';
import {
  locationsHaveLiquorSalesValidator,
  uniqueAddressValidator,
  perOccurenceLimitForLiquorLiability,
  validateAgentLicenseNumber,
  validateLiquorLicenseNumber,
} from 'app/features/attune-bop/models/form-validators';
import { Industry } from '../../../shared/services/naics.service';
import { BopQuoteFormValue, BopQuotePayload } from '../models/bop-policy';
import { AdditionalInsuredsMixin } from 'app/features/attune-bop/mixins/additional-insureds.mixin';
import { ExcessLiabilityMixin } from 'app/features/attune-bop/mixins/excess-liability-form.mixin';
import { WaiverOfSubrogationMixin } from 'app/features/attune-bop/mixins/waiver-of-subrogation.mixin';
import { LocationFormMixin } from 'app/features/attune-bop/mixins/location-form.mixin';
import {
  classificationsHaveLiquor,
  classificationHasByobAlcohol,
  locationsHaveLroBuildings,
  classificationsHaveBeverageStore,
} from '../models/classification-helpers';
import { SampleAccountType } from 'app/shared/sample-accounts/sample-accounts';
import { OnboardingService } from 'app/shared/services/onboarding.service';
import { parseMaskedInt, parseMoney } from 'app/shared/helpers/number-format-helpers';
import { shouldGetBopV2, shouldGetMeToo } from 'app/shared/helpers/account-helpers';
import {
  CoalitionCyberQuestion,
  CyberQuestionEnablementFlags,
  CoalitionCyberFormDataRaw,
  CoalitionCyberFormDataFormatted,
  FirstPartyCoverageNestedQuestion,
  UpsellEarlyDeclineKey,
  NAICS_CODE,
  EngagedIndustriesNestedQuestion,
} from '../../coalition/models/cyber-typings.model';
import { validateNetProfitIsLessThanCompanyRevenue } from '../../coalition/models/cyber-validators.model';
import {
  ESSENTIAL_COVERAGES,
  MOST_POPULAR_COVERAGES,
  CYBER_DEFAULT_UNDERWRITING_QUESTIONS_FOR_REVENUES_UNDER_1M,
  ESSENTIAL_AGGREGATE_LIMIT_INDEX,
  MOST_POPULAR_AGGREGATE_LIMIT_INDEX,
  ESSENTIAL_DEFAULT_RETENTION_INDEX,
  MOST_POPULAR_DEFAULT_RETENTION_INDEX,
  ADMITTED_MAX_REVENUE,
  NAICS_CODES_TO_INDUSTRY_IDS,
} from '../../coalition/models/cyber-constants.model';
import {
  FeatureFlagService,
  BOOLEAN_FLAG_NAMES,
} from '../../../core/services/feature-flag.service';
import {
  formatFormFieldValues,
  getEmployeeCountValue,
  removeIneligibleCyberCoverages,
} from '../../coalition/models/cyber-form-helpers.model';
import { ProductAvailability, ClassCodeSelection } from '../../digital-carrier/models/types';
import { AccountSummaryView } from '../../insured-account/services/insured-account-summary.service';
import { environment } from 'environments/environment';
import { UserService } from 'app/core/services/user.service';
import { SegmentService } from 'app/core/services/segment.service';

const emptyAddress = {
  addressLine1: '',
  addressLine2: null,
  city: '',
  state: '',
  zip: '',
};

const hnoaUwQuestions = [
  'hnoaOver18',
  'hnoaCertificateOfInsurance',
  'hnoa3rdPartyDelivery',
  'hnoaMotorVehicles',
  'hnoaLocationRadius',
];

export const liquorUWEffectiveDate = moment('05-01-2024', 'MM-DD-YYYY');

// List of state capitals for happy-path lookup. Quoting will fail if the city/state/zip do not match up
const happyPathCityLookup: Record<string, [string, string]> = {
  AL: ['Montgomery', '36101'],
  AK: ['Juneau', '99801'],
  AZ: ['Phoenix', '85001'],
  AR: ['Little Rock', '72201'],
  CA: ['Sacramento', '94203'],
  CO: ['Denver', '80201'],
  CT: ['Hartford', '06101'],
  DC: ['Washington', '20011'],
  DE: ['Dover', '19901'],
  FL: ['Tallahassee', '32301'],
  GA: ['Atlanta', '30301'],
  HI: ['Honolulu', '96801'],
  ID: ['Boise', '83701'],
  IL: ['Springfield', '62701'],
  IN: ['Indianapolis', '46201'],
  IA: ['Des Moines', '50301'],
  KS: ['Topeka', '66601'],
  KY: ['Frankfort', '40601'],
  LA: ['Baton Rouge', '70801'],
  ME: ['Augusta', '04330'],
  MD: ['Annapolis', '21401'],
  MA: ['Boston', '02108'],
  MI: ['Lansing', '48901'],
  MN: ['St. Paul', '55101'],
  MS: ['Jackson', '39201'],
  MO: ['Jefferson City', '65101'],
  MT: ['Helena', '59601'],
  NE: ['Lincoln', '68501'],
  NV: ['Carson City', '89701'],
  NH: ['Concord', '03301'],
  NJ: ['Trenton', '08601'],
  NM: ['Santa Fe', '87501'],
  NY: ['Albany', '12201'],
  NC: ['Raleigh', '27601'],
  ND: ['Bismarck', '58501'],
  OH: ['Columbus', '43201'],
  OK: ['Oklahoma City', '73101'],
  OR: ['Salem', '97301'],
  PA: ['Harrisburg', '17101'],
  RI: ['Providence', '02901'],
  SC: ['Columbia', '29201'],
  SD: ['Pierre', '57501'],
  TN: ['Nashville', '37201'],
  TX: ['Austin', '73301'],
  UT: ['Salt Lake City', '84101'],
  VT: ['Montpelier', '05601'],
  VA: ['Richmond', '23218'],
  WA: ['Olympia', '98501'],
  WV: ['Charleston', '25301'],
  WI: ['Madison', '53701'],
  WY: ['Cheyenne', '82001'],
};

const baseStateLocationValidator = (locationsAddresses: UntypedFormArray) => {
  return (control: AbstractControl): ValidationErrors | null => {
    const states = locationsAddresses.value.map((loc: Address) => {
      return loc && loc.state;
    });
    if (INELIGIBLE_STATES.includes(control.value)) {
      return {
        baseStateLocation: {
          value: control.value,
          validationMessage: `We currently do not offer BOP for these base states: ${INELIGIBLE_STATES.join(
            ', '
          )}.`,
        },
      };
    }
    if (states.includes(control.value)) {
      return null;
    }
    return {
      baseStateLocation: {
        value: control.value,
        validationMessage: `The base state is not included in the list of account locations: ${states.join(
          ', '
        )}.`,
      },
    };
  };
};

class FormService extends FormDslSteppedFormBaseService {
  protected _showGuidelinesStep = false;
  protected _showNewAccountStep = false;

  // TODO(NY) - Remove feature flag once this feature is rolled out to all of prod for 2 weeks.
  lessorsRiskPageFeatureFlaggedOn = environment.lroFlowEnabled;
  allowedCSXSproducerCodes = environment.allowedCSXSproducerCodes;

  public fillInHappyPath(state = 'TN', excess = false): void {
    const bopVersion = shouldGetBopV2(state) ? 2 : 1;
    const meToo = shouldGetMeToo(state);

    this.form.patchValue({
      account: true,
      guidelines: true,
      policyInfo: {
        numberOfLocations: 1,
        yearsInBusiness: 5,
        organizationType: 'partnership',
        baseState: state,
        bopVersion: bopVersion,
        meToo: meToo,
        locationAddresses: [
          {
            addressLine1: '123 Main Street',
            addressLine2: 'Floor 2',
            city: happyPathCityLookup[state][0],
            state: state,
            zip: happyPathCityLookup[state][1],
          },
        ],
      },
      liabilityCoverages: {
        damageToPremises: '50000',
        limitPerOccurrenceOfLiabilityAndMedicalExpenses: 1000000,
        limitPerPersonOfMedicalExpenses: 5000,
        employeeBenefitsLiabilityCoverage: {
          optedIn: false,
          retroactiveDate: '01/01/1917',
          eachEmployeeLimit: 1000000,
          aggregateLimit: 1000000,
          deductible: 1000,
        },
        employeeHandbook: true,
        janitorialServices: false,
      },
      additionalCoverages: {
        limitForEmployeeDishonesty: 25000,
        acceptCertifiedActsOfTerrorismCoverage: true,
        hasAdditionalInsuredBusinesses: false,
        employmentRelatedPracticesLiabilityCoverage: {
          optedIn: false,
          retroactiveDate: '03/06/2018',
          eachEmploymentWrongfulActLimit: 10000,
          aggregateLimit: 10000,
          deductible: 5000,
        },
        cyberLiabilityCoverage: {
          optedIn: false,
          selectedCyberCoverage: 'none',
          aggregateLimit: 50000,
          deductible: 1000,
          retroactiveDate: '01/01/2017',
        },
      },
      locations: [
        {
          locationDetails: {
            addressLine1: '123 Main Street',
            addressLine2: 'Floor 2',
            annualRevenue: '1000000',
            city: happyPathCityLookup[state][0],
            state: state,
            zip: happyPathCityLookup[state][1],
            employeeCount: '42',
          },
          locationPrefill: {
            bcegsCommercialCode: '04',
            bopTerritoryCode: '005',
            caDOIZone: null,
            capRisk: 5,
            commEarthquakeTerritoryCode: '21',
            earthquakeMMI100Year: '3.0',
            earthquakeSoilType: 'Soft to Firm Rock(stiff soil)',
            enhancedCrimeFlag: false,
            floodZone: null,
            groupIICode: null,
            groupIICountyName: null,
            hail_risk_3: 7,
            hail_srisk_3: 10,
            hailDamageScore: 1,
            hailRiskScore: 4,
            isoCountyName: null,
            isoDistToCoastRangeCode: '16',
            latitude: '37.229225',
            longitude: '-80.413438',
            ppc: '5',
            roofConditionRating: null,
            wildfireRiskScore: 0,
          },
          buildings: [
            {
              exposure: {
                businessType: 'Office',
                classification: {
                  code: {
                    code: '63631',
                    descriptionCode: 'AccountingServicesCPAs',
                  },
                },
                limitForBusinessPersonalProperty: '$101,000',
                percentSalesPersonalDevices: null,
                constructionType: 'FireResistive',
                storyCount: '1',
                squareFootage: '2000',
                hasAutomaticSprinklerSystem: true,
                buildingLimit: '$0',
                totalSales: '$100,000',
                payroll: '$300,000',
                yearBuilt: 2022,
                roofUpdated: false,
                electricPlumbingHVACUpdated: true,
              },
              coverage: {
                businessIncomeAndExtraExpensesIndemnityInMonths: 12,
                equipmentBreakdownCoverageOptedIn: true,
                utilityServicesTimeElement: 0,
                utilityServicesDirectDamage: 0,
                debrisRemoval: 25000,
                ordinanceLawCoverageValue: 5000,
                windCoverageOptedIn: false,
                windDeductible: 2500,
                windDeductiblePercent: '1%',
                windLossMitigation: {
                  SCBCCompliant: false,
                  level: 'A',
                  roofToWallConnection: 'ToenAils',
                  openingProtection: 'None',
                  doorStrength: 'RSWD',
                  secondaryWaterResistance: false,
                  roofShape: 'Flat',
                  roofAndDeckAttachment: 'Wood',
                },
              },
            },
          ],
        },
      ],
    });

    if (excess) {
      this.form.patchValue({
        excessLiabilityOptIn: true,
        excessLiability: {
          excessLiabilityLimit: 3000000,
        },
      });
    } else {
      this.form.patchValue({
        excessLiabilityOptIn: false,
      });
    }
  }

  showInitialSteps(showNewAccount: boolean, showGuidelines: boolean) {
    this._showNewAccountStep = showNewAccount;
    this._showGuidelinesStep = showGuidelines;
    this.syncAllSteps();
    // nav to the guideline step
    if (showGuidelines) {
      const guidelineStep = showNewAccount ? 1 : 0;
      this.stepWithoutValidation(this.steps[guidelineStep]);
    }
  }

  bopVersion(): BopVersion {
    const bopVersionControl: AbstractControl | null = this.get('policyInfo.bopVersion');
    return bopVersionControl ? bopVersionControl.value : null;
  }

  setBOPVersion(version: BopVersion) {
    const bopVersionControl = this.get('policyInfo.bopVersion') as UntypedFormControl;
    if (!bopVersionControl) {
      throw new Error('Unable to update bop version');
    }
    bopVersionControl.patchValue(version);
  }

  meToo(): boolean {
    const meTooControl: AbstractControl | null = this.get('policyInfo.meToo');
    return meTooControl ? meTooControl.value : false;
  }

  setMeToo(meToo: boolean) {
    const meTooControl = this.get('policyInfo.meToo') as UntypedFormControl;
    if (!meTooControl) {
      throw new Error('Unable to update me too status');
    }
    meTooControl.patchValue(meToo);
  }

  setAgentName(agentName: string) {
    const agentNameFormControl = getControl(this.form, 'policyInfo.agentName');
    if (!agentNameFormControl) {
      throw new Error('Unable to update agent name');
    }

    // We do not want to patch the agent name if the user has already entered a name OR if we patched the name via an edit
    if (!agentNameFormControl.value) {
      patchControl(agentNameFormControl, agentName);
    }
  }

  generateSteps(): RouteFormStep[] {
    // BOP Form Requires LocationFormMixin
    const locationAddressesValue = (<any>this).getLocationAddresses().value;

    // Steps are generated once before the form is initialized
    const locationSteps = !this.form
      ? []
      : flatMap(locationAddressesValue, (_address, index: number) => {
          return [
            {
              args: {},
              displayName: `Location ${index + 1}`,
              slug: `location-${index + 1}`,
              parent: `location-${index + 1}`,
              formPath: `locations.${index}.locationDetails`,
            },
            {
              args: {},
              displayName: `Building Details`,
              slug: `building-${index + 1}-1`,
              parent: `location-${index + 1}`,
              substep: true,
              formPath: `locations.${index}.buildings.0.exposure`,
            },
            this.buildingLessorsRiskStep(index),
            {
              args: {},
              displayName: `Building Coverage`,
              slug: `building-${index + 1}-1-coverages`,
              parent: `location-${index + 1}`,
              substep: true,
              formPath: `locations.${index}.buildings.0.coverage`,
            },
          ];
        });

    const isStep = (step: RouteFormStep | null): step is RouteFormStep => {
      return step !== null;
    };

    return [
      this.newAccountStep(),
      this.guidelinesStep(),
      {
        args: {},
        displayName: `Policy Info`,
        slug: 'policy-info',
        parent: 'policy-info',
        formPath: 'policyInfo',
      },
      ...locationSteps,
      {
        args: {},
        displayName: `Liability Coverages`,
        slug: 'liability-coverages',
        parent: 'liability-coverages',
        formPath: 'liabilityCoverages',
      },
      {
        args: {},
        displayName: `Additional Coverages`,
        slug: 'additional-coverages',
        parent: 'additional-coverages',
        formPath: 'additionalCoverages',
      },
      this.excessLiabilityStep(),
    ].filter(isStep);
  }

  private newAccountStep(): RouteFormStep | null {
    if (this._showNewAccountStep) {
      return {
        args: {},
        displayName: 'Account',
        slug: 'account',
        parent: 'account',
        formPath: 'account',
      };
    }
    return null;
  }

  private guidelinesStep(): RouteFormStep | null {
    if (this._showGuidelinesStep) {
      this.get<UntypedFormControl>('guidelines').setValidators(Validators.requiredTrue);
      return {
        args: {},
        displayName: 'Guidelines',
        slug: 'guidelines',
        parent: 'guidelines',
        formPath: 'guidelines',
      };
    }
    return null;
  }

  private excessLiabilityStep(): RouteFormStep | null {
    // Steps are generated once before the form is initialized
    if (!this.form) {
      return null;
    }
    const excessLiabilityOptInControl = this.get<UntypedFormControl>('excessLiabilityOptIn');
    const showExcessLiabilityStep =
      excessLiabilityOptInControl.enabled && excessLiabilityOptInControl.value;

    if (showExcessLiabilityStep) {
      return {
        args: {},
        displayName: `Excess Liability`,
        slug: 'excess-liability',
        formPath: 'excessLiability',
        parent: 'excess-liability',
      };
    }
    return null;
  }

  private buildingLessorsRiskStep(locationIndex: number): RouteFormStep | null {
    // TODO(NY) - Remove feature flag once this feature is rolled out to all of prod for 2 weeks.
    if (!this.lessorsRiskPageFeatureFlaggedOn) {
      return null;
    }

    // Steps are generated once before the form is initialized
    if (!this.form) {
      return null;
    }

    const lessorsRiskFormPath = `locations.${locationIndex}.buildings.0.exposure.lessorsRisk`;
    const lessorsRiskFormControl = this.get<UntypedFormControl>(lessorsRiskFormPath);

    if (!lessorsRiskFormControl) {
      return null;
    }

    const showLessorsRiskStep = lessorsRiskFormControl.enabled && lessorsRiskFormControl.value;
    if (showLessorsRiskStep) {
      return {
        args: {},
        displayName: "Lessors' Risk Details",
        slug: `building-${locationIndex + 1}-1-lessors-risk`,
        parent: `location-${locationIndex + 1}`,
        substep: true,
        formPath: `locations.${locationIndex}.buildings.0.lessorsRisk`,
      };
    }
    return null;
  }
}

const FormServiceWithMixins = LocationFormMixin(
  ExcessLiabilityMixin(WaiverOfSubrogationMixin(AdditionalInsuredsMixin(FormService))),
  'policyInfo.locationAddresses',
  'locations'
);

@Injectable()
export class AttuneBopQuoteFormService extends FormServiceWithMixins implements OnDestroy {
  form: UntypedFormGroup;
  submitted = false;
  uwQuestions: UWQuestion[];
  showHnoaUwQuestions = false;

  // Properties for Cyber upsell
  private cyberIndustryFlagsSubject: BehaviorSubject<CyberQuestionEnablementFlags | null> =
    new BehaviorSubject(null);

  public productAvailabilitiesSubject: Subject<ProductAvailability[]> = new Subject<
    ProductAvailability[]
  >();
  public accountSummarySubject: Subject<AccountSummaryView> = new Subject<AccountSummaryView>();

  industryId$: Observable<number | null>;
  private industryIdSubject: BehaviorSubject<number | null> = new BehaviorSubject(null);

  public naicsCodeSubject: BehaviorSubject<string | null> = new BehaviorSubject(null);

  // END Properties for Cyber upsell

  public epliOptions: EpliOptions = BOP_V2_EPLI_DEFAULT_OPTIONS;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private featureFlagService: FeatureFlagService,
    private userService: UserService,
    private segmentService: SegmentService
  ) {
    super();
    this.initializeForm();
    this.syncAllSteps();
    this.industryId$ = this.industryIdSubject.asObservable();
  }

  ngOnDestroy() {
    super.ngOnDestroy(); // Clean up mixin subscriptions
  }

  initializeForm() {
    const today = moment().startOf('day');
    const now = moment().startOf('day').add(12, 'hours');
    const effectiveDate = moment().format(US_DATE_MASK);

    const locations = new UntypedFormArray([]);

    const limitPerOccurrenceOfLiabilityAndMedicalExpenses = this.formBuilder.control(
      1000000,
      Validators.required
    );

    const stopGap = this.formBuilder.control({ value: false, disabled: true }, Validators.required);

    const liabilityCoverages = this.formBuilder.group({
      damageToPremises: [50000, Validators.required],
      limitPerOccurrenceOfLiabilityAndMedicalExpenses,
      limitPerPersonOfMedicalExpenses: [5000, Validators.required],
      opticalAndHearingAidEstablishmentsProfessionalLiability: [false, Validators.required],
      opticalAndHearingAidSales: [{ value: 0, disabled: true }, Validators.required],

      liquorLiability: this.formBuilder.group({
        optedIn: [
          { value: null, disabled: true },
          [
            Validators.required,
            locationsHaveLiquorSalesValidator(locations),
            perOccurenceLimitForLiquorLiability(
              limitPerOccurrenceOfLiabilityAndMedicalExpenses,
              1000000
            ),
          ],
        ],
        eachCommonCauseLimit: [1000000, Validators.required],
        aggregateLimit: [2000000, Validators.required],
        liquorLicenseNumber: [
          { value: '', disabled: false },
          [Validators.required, validateLiquorLicenseNumber],
        ],
      }),

      printersErrorsAndOmissionsProfessionalLiability: [false, Validators.required],
      printersErrorsAndOmissionsSalesOrPayroll: [{ value: 0, disabled: true }, Validators.required],

      funeralDirectorsProfessionalLiability: [false, Validators.required],

      barberShopsAndHairSalonsProfessionalLiability: [false, Validators.required],
      barberShopsNumberOfOperators: [
        { value: 1, disabled: true },
        [Validators.required, Validators.min(1)],
      ],

      beautySalonsProfessionalLiability: [false, Validators.required],
      beautySalonsDescriptionOfAdditionalServices: [
        { value: 'Beauty Parlors and Hair Styling Salons including Nail Salons', disabled: true },
      ],
      beautySalonsNumberOfOperators: [
        { value: 1, disabled: true },
        [Validators.required, Validators.min(1)],
      ],

      veterinariansProfessionalLiability: [false, Validators.required],
      numberOfVeterinarians: [
        { value: 1, disabled: true },
        [Validators.required, Validators.min(1)],
      ],
      veterinariansOnlyTreatHousePets: [{ value: true, disabled: true }, Validators.required],

      // Contractors (older form)
      acceptSnowPlowCompletedOpsCoverage: [false, Validators.required],
      eachCoveredJobSiteLimit: [EACH_COVERED_JOB_SITE_LIMIT_DEFAULT.toString()],
      propertyInTransitLimit: [
        '5000',
        [Validators.required, Validators.min(5000), Validators.max(100000)],
      ],
      // Contractors (newer form)
      installationLimit: [5000, Validators.required],
      toolsBlanketLimit: [3000, Validators.required],
      toolsPerItemLimit: [500, Validators.required],
      equipmentLeftInVehicle: [null, Validators.required],
      stressTestsWaterLines: [null, Validators.required],
      employeeHandbook: [null, Validators.required],
      janitorialServices: [null, Validators.required],
      stopGap: stopGap,

      employeeBenefitsLiabilityCoverage: this.formBuilder.group({
        optedIn: [false, Validators.required],
        retroactiveDate: [now.format(US_DATE_MASK), Validators.required],
        eachEmployeeLimit: [1000000, Validators.required],
        aggregateLimit: [1000000, Validators.required],
        deductible: [1000, Validators.required],
      }),

      // the following 5 HNOA UW questions are not sent to the backend and only used to to possibly prevent the frontend from sending hnoaNumberOfDrivers
      hnoaOver18: [false, Validators.required],
      hnoaCertificateOfInsurance: [false, Validators.required],
      hnoa3rdPartyDelivery: [false, Validators.required],
      hnoaMotorVehicles: [false, Validators.required],
      hnoaLocationRadius: [false, Validators.required],
      acceptHiredNonOwnedAutoCoverage: [false, Validators.required],
      hnoaNumberOfDrivers: [
        { value: null, disabled: true },
        [Validators.required, Validators.min(1)],
      ],

      eligibilityUWQuestions: this.getEligibilityUWFormGroup(),
    });

    const additionalInsuredBusinesses = this.formBuilder.array([], Validators.required);
    additionalInsuredBusinesses.disable();

    const waiversOfSubrogation = this.formBuilder.array([], Validators.required);
    waiversOfSubrogation.disable();

    const employmentPractices = this.formBuilder.group({
      optedIn: [false, Validators.required],
      retroactiveDate: [now.format(US_DATE_MASK), Validators.required], // v1 Only
      eachEmploymentWrongfulActLimit: [25000, Validators.required], // v1 Only
      aggregateLimit: [25000, Validators.required], // v1 Only
      deductible: [5000, Validators.required], // v1 Only

      // BOPv2 Controls
      aggregateLimitV2: [25000, Validators.required],
      defenseLimitV2: [12500, Validators.required],
      deductibleV2: [5000, [Validators.required, this.epliDeductibleV2Validator()]],
      perLocationPartTimeEmployees: this.formBuilder.array([0]), // FIXME: Validate <= numEmployees
    });

    const additionalCoverages = this.formBuilder.group({
      employmentRelatedPracticesLiabilityCoverage: employmentPractices,
      acceptCertifiedActsOfTerrorismCoverage: [false, Validators.required],
      limitForEmployeeDishonesty: [5000, Validators.required],
      hasAdditionalInsuredBusinesses: [false, Validators.required],
      additionalInsuredBusinesses: additionalInsuredBusinesses,
      cyberLiabilityCoverage: this.formBuilder.group(
        {
          optedIn: [false, Validators.required],
          selectedCyberCoverage: ['none', Validators.required],
          // Controls for coverage endorsement
          aggregateLimit: [50000, Validators.required],
          firstPartyLimit: [25000, Validators.required],
          deductible: [1000, Validators.required],
          retroactiveDate: [effectiveDate, Validators.required],
          // Controls for standalone Coalition policy
          [CoalitionCyberQuestion.COMPANY_INDUSTRY_ID]: [null, Validators.required],
          [CoalitionCyberQuestion.HAS_TECH_EO]: [null, Validators.required],
          [CoalitionCyberQuestion.COMPANY_REVENUE]: [
            null,
            [Validators.required, validateCurrencyGreaterThanZero],
          ],
          [CoalitionCyberQuestion.COMPANY_GROSS_PROFIT_NET_REVENUE]: [
            null,
            [Validators.required, validateCurrencyGreaterThanZero],
          ],
          [CoalitionCyberQuestion.AGGREGATE_LIMIT]: [null, Validators.required],
          [CoalitionCyberQuestion.DEFAULT_RETENTION]: [null, Validators.required],
          [CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS]: [null, Validators.required],
          [CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_COUNT]: [null, Validators.required],
          [CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT]: [null, Validators.required],
          [CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT_MORE_THAN_500K]: [
            null,
            [Validators.required, validateCurrencyGreaterThanValue(500_000)],
          ],
          [CoalitionCyberQuestion.PRIOR_CLAIMS]: [null, Validators.required],
          [CoalitionCyberQuestion.PRIOR_CLAIMS_EXPLANATION]: [null, Validators.required],
          [CoalitionCyberQuestion.DUAL_CONTROL_5K]: [null, Validators.required],
          [CoalitionCyberQuestion.ADMINISTRATIVE_DUAL_CONTROL]: [null, Validators.required],
          [CoalitionCyberQuestion.MFA_EMAIL]: [null, Validators.required],
          [CoalitionCyberQuestion.MFA_REMOTE_ACCESS]: [null, Validators.required],
          [CoalitionCyberQuestion.MFA_VPN]: [null, Validators.required],
          [CoalitionCyberQuestion.MFA_OTHER_PRIVILEGED_ACCOUNTS]: [null, Validators.required],
          [CoalitionCyberQuestion.ENGAGED_INDUSTRIES]: this.formBuilder.group({
            [EngagedIndustriesNestedQuestion.ADULT]: [null],
            [EngagedIndustriesNestedQuestion.CRYPTO_BLOCKCHAIN]: [null],
            [EngagedIndustriesNestedQuestion.GAMBLING]: [null],
            [EngagedIndustriesNestedQuestion.MSP_MSSP_RNASP]: [null],
            [EngagedIndustriesNestedQuestion.PAYMENT_PROCESSING]: [null],
          }),
        },
        {
          validators: [validateNetProfitIsLessThanCompanyRevenue()],
        }
      ),
      hasWaiversOfSubrogation: [false, Validators.required],
      waiversOfSubrogation: waiversOfSubrogation,
    });

    const cyberLiabilityCoverageGroup = getFormGroup(additionalCoverages, 'cyberLiabilityCoverage');

    const aggregateLimitControl = getControl(cyberLiabilityCoverageGroup, 'aggregateLimit');
    const firstPartyLimitControl = getControl(cyberLiabilityCoverageGroup, 'firstPartyLimit');
    const deductibleControl = getControl(cyberLiabilityCoverageGroup, 'deductible');
    const retroactiveDateControl = getControl(cyberLiabilityCoverageGroup, 'retroactiveDate');

    const cyberOptInControl = getControl(cyberLiabilityCoverageGroup, 'optedIn');

    const cyberProductTypeControl = getControl(
      cyberLiabilityCoverageGroup,
      'selectedCyberCoverage'
    );
    const cyberIndustryIdControl = getControl(
      cyberLiabilityCoverageGroup,
      CoalitionCyberQuestion.COMPANY_INDUSTRY_ID
    );
    const hasTechEoControl = getControl(
      cyberLiabilityCoverageGroup,
      CoalitionCyberQuestion.HAS_TECH_EO
    );
    const companyNetRevenueControl = getControl(
      cyberLiabilityCoverageGroup,
      CoalitionCyberQuestion.COMPANY_GROSS_PROFIT_NET_REVENUE
    );
    const standaloneAggregateLimitControl = getControl(
      cyberLiabilityCoverageGroup,
      CoalitionCyberQuestion.AGGREGATE_LIMIT
    );
    const defaultRetentionControl = getControl(
      cyberLiabilityCoverageGroup,
      CoalitionCyberQuestion.DEFAULT_RETENTION
    );
    const awareOfPriorClaimsControl = getControl(
      cyberLiabilityCoverageGroup,
      CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS
    );

    awareOfPriorClaimsControl.valueChanges.subscribe((awareOfPriorClaims) => {
      const priorClaimsQuestions = [
        CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_COUNT,
        CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT,
        CoalitionCyberQuestion.PRIOR_CLAIMS,
        CoalitionCyberQuestion.PRIOR_CLAIMS_EXPLANATION,
      ].map((controlName) => getControl(cyberLiabilityCoverageGroup, controlName));
      priorClaimsQuestions.forEach((control) => {
        enableDisableControl(
          control,
          awareOfPriorClaimsControl.enabled && awareOfPriorClaims === 'Yes'
        );
      });
    });

    const awareOfPriorClaimsAmountControl = getControl(
      cyberLiabilityCoverageGroup,
      CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT
    );
    const awareOfPriorClaimsAmountOver500kControl = getControl(
      cyberLiabilityCoverageGroup,
      CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT_MORE_THAN_500K
    );
    awareOfPriorClaimsAmountControl.valueChanges.subscribe((claimsAmount) => {
      enableDisableControl(
        awareOfPriorClaimsAmountOver500kControl,
        awareOfPriorClaimsAmountControl.enabled && claimsAmount === '>500,000'
      );
    });

    // Set listeners on the NAICS code and industry ID control, so we can fetch industry data as the values change.
    this.naicsCodeSubject
      .pipe(filter((naicsCode) => !!naicsCode))
      .subscribe((naicsCode: string) => {
        this.industryIdSubject.next(NAICS_CODES_TO_INDUSTRY_IDS[naicsCode]);
      });

    cyberIndustryIdControl.valueChanges.subscribe((industry: Industry) => {
      if (cyberIndustryIdControl.disabled || industry === null) {
        return;
      }
      this.industryIdSubject.next(industry.id);
    });

    // The user cannot select a default retention directly; it is defaulted based on the chosen aggregate limit
    standaloneAggregateLimitControl.valueChanges.subscribe((limit: number | null) => {
      if (limit === ESSENTIAL_AGGREGATE_LIMIT_INDEX) {
        defaultRetentionControl.patchValue(ESSENTIAL_DEFAULT_RETENTION_INDEX);
      } else if (limit === MOST_POPULAR_AGGREGATE_LIMIT_INDEX) {
        defaultRetentionControl.patchValue(MOST_POPULAR_DEFAULT_RETENTION_INDEX);
      } else {
        defaultRetentionControl.patchValue(null);
      }
    });

    /* We are checking for a change in the per occurence limit to see if we need to re-check
    validation for liquor liability. */
    (<UntypedFormControl>(
      liabilityCoverages.get('limitPerOccurrenceOfLiabilityAndMedicalExpenses')
    )).valueChanges.subscribe((value) => {
      /* TODO Potentially move to another function */
      const optedIn = <UntypedFormControl>liabilityCoverages.get('liquorLiability.optedIn');
      if (optedIn) {
        optedIn.updateValueAndValidity();
      }
    });

    (<UntypedFormControl>liabilityCoverages.get('liquorLiability.optedIn'))?.valueChanges.subscribe(
      (liquorLiabililityOption) => {
        const licenseControl = liabilityCoverages.get('liquorLiability.liquorLicenseNumber');
        const liquorUWQuestionsControl = liabilityCoverages.get(
          'eligibilityUWQuestions.liquorLiabilityUWQuestions'
        );

        if (licenseControl) {
          enableDisableControl(
            licenseControl,
            this.shouldShowLiquorLicense(liquorLiabililityOption, this.form.value.locations)
          );
        }

        if (liquorUWQuestionsControl) {
          enableDisableControl(
            liquorUWQuestionsControl,
            this.shouldShowLiquorLiabilityUWQuestions(
              liquorLiabililityOption,
              this.form.value.locations
            )
          );
        }
      }
    );

    (<UntypedFormControl>(
      additionalCoverages.get('hasAdditionalInsuredBusinesses')
    )).valueChanges.subscribe((value) => {
      if (value) {
        additionalInsuredBusinesses.enable();
        if (additionalInsuredBusinesses.length === 0) {
          this.addAdditionalInsuredBusiness(
            this.get<UntypedFormArray>('additionalCoverages.additionalInsuredBusinesses')
          );
        }
      } else {
        additionalInsuredBusinesses.disable();
      }
    });

    (<UntypedFormControl>additionalCoverages.get('hasWaiversOfSubrogation')).valueChanges.subscribe(
      (value) => {
        if (value && this.bopVersion() === 1) {
          enableDisableControl(waiversOfSubrogation, true);
          if (waiversOfSubrogation.length === 0) {
            this.addWaiverOfSubrogation(
              this.get<UntypedFormArray>('additionalCoverages.waiversOfSubrogation')
            );
          }
        } else {
          enableDisableControl(waiversOfSubrogation, false);
        }
      }
    );

    getControl(
      additionalCoverages,
      'employmentRelatedPracticesLiabilityCoverage.eachEmploymentWrongfulActLimit'
    ).valueChanges.subscribe((eachEmploymentWrongfulActLimit: number) => {
      getControl(
        this.form,
        'additionalCoverages.employmentRelatedPracticesLiabilityCoverage.aggregateLimit'
      ).patchValue(eachEmploymentWrongfulActLimit);
    });

    getControl(
      additionalCoverages,
      'additionalCoverages.employmentRelatedPracticesLiabilityCoverage.aggregateLimit'
    );

    getControl(liabilityCoverages, 'acceptHiredNonOwnedAutoCoverage').valueChanges.subscribe(
      (hnoaValue: boolean) => {
        const numDriversControl = getControl(liabilityCoverages, 'hnoaNumberOfDrivers');
        if (this.bopVersion() === 2) {
          enableDisableControl(numDriversControl, hnoaValue);
        } else {
          enableDisableControl(numDriversControl, false);
        }
        hnoaUwQuestions.forEach((ctrlName) => {
          const ctrl = getControl(this.form, `liabilityCoverages.${ctrlName}`);
          if (this.bopVersion() === 2) {
            enableDisableControl(ctrl, hnoaValue && this.showHnoaUwQuestions);
          } else {
            enableDisableControl(ctrl, false);
          }
        });
      }
    );

    const eligibilityUWQuestionsGroup = getFormGroup(liabilityCoverages, 'eligibilityUWQuestions');
    this.initEligibilityUWNoneOfTheAboveSubscription(eligibilityUWQuestionsGroup);
    this.initEligibilityUWCheckboxSubscriptions(eligibilityUWQuestionsGroup);

    Object.keys(PROF_LIABILITY_FIELD_NAMES).forEach(
      (professionalLiabilityFieldName: OperationsCoverage) => {
        const profLiabField = getControl(liabilityCoverages, professionalLiabilityFieldName);
        profLiabField.valueChanges.subscribe((plValue: boolean) => {
          const childFieldNames = PROF_LIABILITY_FIELD_NAMES[professionalLiabilityFieldName];
          childFieldNames.forEach((childFieldName: string) => {
            const childField = getControl(liabilityCoverages, childFieldName);
            enableDisableControl(childField, plValue);
          });
        });
      }
    );

    const excessLiabilityOptIn = this.formBuilder.control(
      { value: false, disabled: true },
      Validators.required
    );
    const excessLiability = this.createExcessLiabilityFormGroup();
    this.showOrHideOccurenceSubfields(liabilityCoverages, excessLiabilityOptIn, stopGap);

    const locationAddressesFormArray = new UntypedFormArray([]);

    const oneYearFromToday: moment.Moment = moment().add(1, 'years');

    const policyInfo = this.formBuilder.group(
      {
        effectiveDate: [
          effectiveDate,
          [
            Validators.required,
            minDateExceededValidator(today, 'day'),
            validatorWithMessage(
              maxDateExceededValidator(oneYearFromToday),
              'Maximum effective date allowed is one year away.'
            ),
          ],
        ],
        bopVersion: null,
        meToo: false,
        baseState: [
          '',
          [Validators.required, baseStateLocationValidator(locationAddressesFormArray)],
        ],
        yearsInBusiness: [null, [Validators.required, rangeValidator(0, 999)]],
        locationAddresses: locationAddressesFormArray,
        organizationType: ['', Validators.required],
        irpmVersion: null,
        ratingModelVersion: null,
        agentName: [{ value: '', disabled: true }, Validators.required],
        agentLicenseNumber: [
          { value: '', disabled: true },
          [Validators.required, validateAgentLicenseNumber],
        ],
      },
      {
        validator: uniqueAddressValidator,
      }
    );

    const baseStateControl = getControl(policyInfo, 'baseState');
    const baseState$ = observableMerge(
      baseStateControl.valueChanges,
      observableOf(baseStateControl.value)
    );
    const bopVersionControl = getControl(policyInfo, 'bopVersion');
    const bopVersion$ = observableMerge(
      bopVersionControl.valueChanges,
      observableOf(bopVersionControl.value)
    );

    this.setupExcessObservables(baseStateControl, excessLiability);
    this.setupEPLIV2DeductibleObservables(baseStateControl, employmentPractices);
    this.setupCyberOptionsUpdateSubscription({
      aggregateLimitControl,
      firstPartyLimitControl,
      deductibleControl,
      baseStateControl,
      bopVersionControl,
    });
    const cyberUpsellEnabled$ = this.featureFlagService.isEnabled(
      BOOLEAN_FLAG_NAMES.COALITION_CYBER_STANDALONE_UPSELL
    );

    const cyberIsAvailable$ = this.productAvailabilitiesSubject.pipe(
      map((availabilities) => {
        return availabilities.some(({ pasSource, product, classCodeSelection }) => {
          return (
            pasSource === 'coalition' &&
            product === 'cyber_admitted' &&
            classCodeSelection === ClassCodeSelection.ALL
          );
        });
      })
    );

    const accountHasCyberPolicies$ = this.accountSummarySubject.pipe(
      map((accountSummary) => {
        if (accountSummary.status === 'success') {
          return accountSummary.policyTerms.some(({ pasSource, product, status }) => {
            return (
              pasSource === 'coalition' &&
              ['cyber_admitted', 'cyber_surplus'].includes(product) &&
              ['in_force', 'scheduled'].includes(status)
            );
          });
        }
      })
    );

    combineLatest([
      baseState$,
      bopVersion$,
      observableMerge(
        cyberProductTypeControl.valueChanges,
        observableOf(cyberProductTypeControl.value)
      ),
      this.cyberIndustryFlagsSubject,
      this.naicsCodeSubject,
      cyberIsAvailable$,
    ]).subscribe(
      ([baseState, bopVersion, cyberProductType, industryFlags, naicsCode, cyberIsAvailable]: [
        string,
        BopVersion,
        CyberProductType,
        CyberQuestionEnablementFlags | null,
        string | null,
        boolean
      ]) => {
        const requiredStandaloneControls = [
          CoalitionCyberQuestion.COMPANY_REVENUE,
          CoalitionCyberQuestion.AGGREGATE_LIMIT,
          CoalitionCyberQuestion.DEFAULT_RETENTION,
          CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS,
          CoalitionCyberQuestion.DUAL_CONTROL_5K,
          CoalitionCyberQuestion.ADMINISTRATIVE_DUAL_CONTROL,
          CoalitionCyberQuestion.MFA_EMAIL,
          CoalitionCyberQuestion.MFA_REMOTE_ACCESS,
          CoalitionCyberQuestion.MFA_VPN,
          CoalitionCyberQuestion.MFA_OTHER_PRIVILEGED_ACCOUNTS,
          CoalitionCyberQuestion.ENGAGED_INDUSTRIES,
        ].map((controlName) => getControl(cyberLiabilityCoverageGroup, controlName));
        const standaloneProductControls = [
          ...requiredStandaloneControls,
          cyberIndustryIdControl,
          hasTechEoControl,
          companyNetRevenueControl,
        ];
        const coverageEndorsementControls = [
          deductibleControl,
          retroactiveDateControl,
          aggregateLimitControl,
          firstPartyLimitControl,
        ];
        if (cyberIsAvailable && cyberProductTypeControl.enabled) {
          if (cyberProductType === 'endorsement') {
            // Enable coverage endorsement controls
            deductibleControl.enable();
            enableDisableControl(retroactiveDateControl, bopVersion === 1);
            const isSplitLimitState = BOP_V2_CYBER_SPLIT_STATES.includes(baseState);
            const useAggregateLimitControl = bopVersion === 1 || !isSplitLimitState;
            enableDisableControl(aggregateLimitControl, useAggregateLimitControl);
            enableDisableControl(firstPartyLimitControl, !useAggregateLimitControl);
            // Disable standalone product controls
            standaloneProductControls.forEach((control) => enableDisableControl(control, false));

            // The optedIn flag is how Guidewire handles the endorsement, so set it true for the endorsement case
            cyberOptInControl.patchValue(true);
          } else if (cyberProductType === 'coalition-cyber') {
            // Enable standalone product controls
            requiredStandaloneControls.forEach((control) => enableDisableControl(control, true));
            enableDisableControl(cyberIndustryIdControl, !naicsCode);
            if (industryFlags) {
              const { require_tech_eo, use_gross_profit_net_revenue } = industryFlags;
              enableDisableControl(hasTechEoControl, require_tech_eo);
              enableDisableControl(companyNetRevenueControl, use_gross_profit_net_revenue);
            }
            // Disable coverage endorsement controls
            coverageEndorsementControls.forEach((control) => enableDisableControl(control, false));

            // The optedIn flag is how Guidewire handles the endorsement, so set it false for non-endorsement cases
            cyberOptInControl.patchValue(false);
          } else if (cyberProductType === 'none') {
            // Disable all controls
            coverageEndorsementControls.forEach((control) => enableDisableControl(control, false));
            standaloneProductControls.forEach((control) => enableDisableControl(control, false));

            // The optedIn flag is how Guidewire handles the endorsement, so set it false for non-endorsement cases
            cyberOptInControl.patchValue(false);
          }
        } else {
          // Disable all controls
          coverageEndorsementControls.forEach((control) => enableDisableControl(control, false));
          standaloneProductControls.forEach((control) => enableDisableControl(control, false));
          enableDisableControl(cyberProductTypeControl, false);
        }
      }
    );

    getControl(policyInfo, 'effectiveDate').valueChanges.subscribe((policyEffDate: string) => {
      this.setCyberRetroDateAsEffectiveDate(policyEffDate);
    });

    locationAddressesFormArray.valueChanges.subscribe(() => {
      baseStateControl.updateValueAndValidity({
        emitEvent: false,
      });
    });

    const account = this.formBuilder.control(null);
    const guidelines = this.formBuilder.control(null);

    // Main BOP Quote FormGroup
    this.form = this.formBuilder.group({
      account,
      guidelines,
      policyInfo,
      locations,
      liabilityCoverages,
      additionalCoverages,
      excessLiabilityOptIn,
      excessLiability,
    });

    this.setupExposureObservables();

    this.getBopExposure$().subscribe((bopExposure: BopExposureInfo) => {
      this.updateFloridaAgentFields(bopExposure);
      this.updateCyberLiability(bopExposure);
      this.updateEBLC(bopExposure);
      this.updateEPLI(bopExposure);
      this.updateHNOA(bopExposure);
      this.updateContractorFields(bopExposure);
      this.updateBOPEligibilityUWQuestions(bopExposure);
      this.updateBOPUnderwritingQuestions(bopExposure);
      this.updateXSQuestions(bopExposure);
      this.updateProfessionalLiabilities(bopExposure);
      this.updateBuildingLimit(bopExposure);
      this.updateBuildingLROStep(bopExposure);
      this.updateWaiverOfSubrogation(bopExposure);

      // Since NY is the only state that still uses BOPv1, the form should always be set to NY Me Too if BOP is V1
      this.setMeToo(bopExposure.bopVersion === 1 && bopExposure.baseState === 'NY');

      // NOTE: there is another liquor aggregate listener in bop-quote-form-component
      this.updateLiquorControls(bopExposure);
    });
  }

  getEligibilityUWFormGroup() {
    return this.formBuilder.group({
      wholesalerAndMercantileOperations: this.formBuilder.group(
        {
          importingGoods: [false, Validators.required],
          manufacturing1: [false, Validators.required],
          repackaging: [false, Validators.required],
          sellingWithInsuredsBranding: [false, Validators.required],
          sellingProductsManufacturedByOthers: [false, Validators.required],
          distributingProducts: [false, Validators.required],
          otherOperationsNotMentioned: [false, Validators.required],
          otherOperationsText: [{ value: '', disabled: true }, Validators.required],
        },
        { validators: requireCheckboxesToBeCheckedValidator() }
      ),
      liveEntertainmentActivities: this.formBuilder.group(
        {
          barGames: [false, Validators.required],
          dancing: [false, Validators.required],
          incidentalMusic: [false, Validators.required],
          karaoke: [false, Validators.required],
          DJs: [false, Validators.required],
          otherActivitiesNotMentioned: [false, Validators.required],
          otherActivitiesText: [{ value: '', disabled: true }, Validators.required],
          noneOfTheAbove: [false, Validators.required],
        },
        { validators: requireCheckboxesToBeCheckedValidator() }
      ),
      nailSalonBeautyParlorBarberShopServices: this.formBuilder.group(
        {
          medispaOrCosmeticServices: this.formBuilder.group({
            botox: [false, Validators.required],
            chemicalPeels: [false, Validators.required],
            coolsculpting: [false, Validators.required],
            dermabrasion: [false, Validators.required],
            eyelashExtensions: [false, Validators.required],
            hairTransplant: [false, Validators.required],
            laserHairRemoval: [false, Validators.required],
            laserSkinResurfacing: [false, Validators.required],
            skinTanning: [false, Validators.required],
            surgicalCosmeticRemedies: [false, Validators.required],
            tattooOrMicroblading: [false, Validators.required],
          }),
          pedicuresManicures: [false, Validators.required],
          hairStyling: [false, Validators.required],
          beardShave: [false, Validators.required],
          makeupArtistry: [false, Validators.required],
          waxing: [false, Validators.required],
          massages: [false, Validators.required],
          otherServicesNotMentioned: [false, Validators.required],
          otherServicesText: [{ value: '', disabled: true }, Validators.required],
        },
        { validators: requireCheckboxesToBeCheckedValidator() }
      ),
      totalSubcontractorCostsPercentage: [null, [Validators.required, percentValidator]],
      liquorLiabilityUWQuestions: this.formBuilder.group({
        liquorLicenseSuspendedInLast10Years: [null, Validators.required],
        patronAgeCheckUnder40: [null, Validators.required],
        identificationCheckedElectronically: [{ value: null, disabled: true }],
        yearsOfLiquorExperience: [null, [Validators.required, rangeValidator(0, 999)]],
        requireAlcoholTraining: [null, Validators.required],
        yearsLicenseIssuedFor: [null, [Validators.required, rangeValidator(0, 999)]],
        liquorSalesOrLowerPrice: [null, Validators.required],
      }),
    });
  }

  initCheckboxControlledTextField(
    form: UntypedFormGroup,
    path: string,
    checkboxName: string,
    textFieldName: string
  ) {
    const formGroup = getFormGroup(form, path);
    const checkboxControl = getControl(formGroup, checkboxName);
    const textControl = getControl(formGroup, textFieldName);
    combineLatest([
      formGroup.statusChanges.pipe(
        map((status) => status !== 'DISABLED'),
        distinctUntilChanged()
      ),
      checkboxControl.valueChanges,
    ]).subscribe(([isFormGroupEnabled, checkboxValue]) => {
      if (isFormGroupEnabled) {
        enableDisableControl(textControl, checkboxValue);
      }
    });
  }

  initEligibilityUWCheckboxSubscriptions(eligibilityUWQuestionsGroup: UntypedFormGroup) {
    this.initCheckboxControlledTextField(
      eligibilityUWQuestionsGroup,
      'wholesalerAndMercantileOperations',
      'otherOperationsNotMentioned',
      'otherOperationsText'
    );
    this.initCheckboxControlledTextField(
      eligibilityUWQuestionsGroup,
      'liveEntertainmentActivities',
      'otherActivitiesNotMentioned',
      'otherActivitiesText'
    );
    this.initCheckboxControlledTextField(
      eligibilityUWQuestionsGroup,
      'nailSalonBeautyParlorBarberShopServices',
      'otherServicesNotMentioned',
      'otherServicesText'
    );
    this.initCheckboxControlledTextField(
      eligibilityUWQuestionsGroup,
      'liquorLiabilityUWQuestions',
      'patronAgeCheckUnder40',
      'identificationCheckedElectronically'
    );
  }

  initEligibilityUWNoneOfTheAboveSubscription(eligibilityUWQuestionsGroup: UntypedFormGroup) {
    const formGroup = getFormGroup(eligibilityUWQuestionsGroup, 'liveEntertainmentActivities');
    const noneOfTheAboveControl = getControl(formGroup, 'noneOfTheAbove');
    const aboveControls = Object.values(formGroup.controls).filter(
      (control) => control.value === false && control !== noneOfTheAboveControl
    );
    noneOfTheAboveControl.valueChanges.subscribe((checkboxVal) => {
      if (checkboxVal) {
        aboveControls.forEach((control) => control.setValue(false));
      }
    });
    aboveControls.forEach((control) => {
      control.valueChanges.subscribe((checkboxVal) => {
        if (checkboxVal) {
          noneOfTheAboveControl.setValue(false);
        }
      });
    });
  }

  yesToAllPreHnoaQuestions(): boolean {
    return hnoaUwQuestions.every(
      (ctrlName) => getControl(this.form, `liabilityCoverages.${ctrlName}`).value
    );
  }

  patchSampleQuoteInfo(sampleType: SampleAccountType) {
    const formValues = OnboardingService.getSampleQuoteInfo(sampleType);
    this.form.patchValue(formValues);
  }

  setupEPLIV2DeductibleObservables(
    baseStateControl: UntypedFormControl,
    epliGroup: UntypedFormGroup
  ) {
    const aggregateLimitV2Control: UntypedFormControl = getControl(epliGroup, 'aggregateLimitV2');
    const defenseLimitV2Control: UntypedFormControl = getControl(epliGroup, 'defenseLimitV2');
    const deductibleV2Control: UntypedFormControl = getControl(epliGroup, 'deductibleV2');

    combineLatest(
      baseStateControl.valueChanges,
      aggregateLimitV2Control.valueChanges,
      defenseLimitV2Control.valueChanges
    )
      .pipe(
        startWith([
          baseStateControl.value,
          aggregateLimitV2Control.value,
          defenseLimitV2Control.value,
        ])
      )
      .subscribe(([baseState, aggregateLimit, defenseLimit]) => {
        let availableDeductibles = [2500, 5000];
        if (BOP_V2_STATES_WITHOUT_2500_EPLI_DEDUCTIBLE.includes(baseState)) {
          availableDeductibles = [5000];
        }

        if (BOP_V2_EPLI_SPLIT_STATES.includes(baseState)) {
          if (defenseLimit >= 50000) {
            availableDeductibles.push(10000);
          }
          if (defenseLimit >= 125000) {
            availableDeductibles.push(25000);
          }
        } else {
          if (aggregateLimit >= 100000) {
            availableDeductibles.push(10000);
          }
          if (aggregateLimit >= 250000) {
            availableDeductibles.push(25000);
          }
        }

        this.epliOptions.deductibleV2 = Object.keys(BOP_V2_DEDUCTIBLE_OPTIONS)
          .filter((k: string) => {
            return availableDeductibles.includes(BOP_V2_DEDUCTIBLE_OPTIONS[k]);
          })
          .reduce((filteredOptions: { [key: string]: number }, key: string) => {
            filteredOptions[key] = BOP_V2_DEDUCTIBLE_OPTIONS[key];
            return filteredOptions;
          }, {});
        clampControl(deductibleV2Control, Object.values(this.epliOptions.deductibleV2));
      });
  }

  showOrHideOccurenceSubfields(
    liabilityCoverages: UntypedFormGroup,
    excessLiabilityOptIn: UntypedFormControl,
    stopGapControl: UntypedFormControl
  ) {
    const liabilityAndMedicalExpensesLimitControl = getControl(
      liabilityCoverages,
      'limitPerOccurrenceOfLiabilityAndMedicalExpenses'
    );
    combineLatest(
      observableMerge(
        liabilityAndMedicalExpensesLimitControl.valueChanges,
        observableOf(liabilityAndMedicalExpensesLimitControl.value)
      ),
      this.getBopExposure$(),
      this.userService.getUser()
    ).subscribe(([liabilityAndMedicalExpensesLimitValue, bopExposure, user]) => {
      if (stopGapControl) {
        const stopGapState = bopExposure.locations.some((loc: BopLocation) => {
          return INCLUDED_STOP_GAP_STATES.includes(loc.locationDetails.state);
        });
        enableDisableControl(
          stopGapControl,
          bopExposure.bopVersion === 2 &&
            liabilityAndMedicalExpensesLimitValue >= STOP_GAP_PER_OCCURENCE_THRESHOLD &&
            stopGapState
        );
      }

      if (this.allowedCSXSproducerCodes.includes(user.producer)) {
        if (liabilityAndMedicalExpensesLimitValue < EXCESS_LIABILITY_PER_OCCURRENCE_THRESHOLD) {
          excessLiabilityOptIn.disable();
          return;
        }

        if (bopExposure.bopVersion === 1) {
          excessLiabilityOptIn.enable();
          return;
        }
        enableDisableControl(
          excessLiabilityOptIn,
          bopExposure.locations.every((location: BopLocation) => {
            if (EXCLUDED_V2_EXCESS_STATES.includes(location.locationDetails.state)) {
              return false;
            }
            return location.buildings.every((building: BopBuilding) => {
              if (!building.exposure.classification.code) {
                return true;
              }
              return !EXCLUDED_V2_EXCESS_CODES.includes(building.exposure.classification.code.code);
            });
          })
        );
      }
    });
  }

  setupExcessObservables(baseStateControl: UntypedFormControl, excessLiability: UntypedFormGroup) {
    baseStateControl.valueChanges.subscribe((state) => {
      this.updateCoverageOptionsByBaseState(state, excessLiability);
    });

    getControl(excessLiability, 'excessLiabilityCoverageIsScheduled').valueChanges.subscribe(
      (optedIn) => optedIn && this.patchEmployersLiabilityCoverage(excessLiability)
    );
  }

  patchEmployersLiabilityCoverage(excessLiability: UntypedFormGroup) {
    excessLiability.patchValue({
      excessLiabilityPerAccidentCoverage: values(this.excessLiabilityPerAccidentCoverageOptions)[0],
      excessLiabilityPerDiseaseCoverage: values(this.excessLiabilityPerDiseaseCoverageOptions)[0],
      excessLiabilityPerPolicyCoverage: values(this.excessLiabilityPerPolicyCoverageOptions)[0],
    });
  }

  getUWQuestionCodes(buildingClassificationStrings: string[]) {
    const questionCodes: QuestionCode[] = buildingClassificationStrings
      .reduce((acc: Array<QuestionCode>, c) => {
        return acc.concat(REQUIRED_UW_QUESTIONS_BY_BUILDING_CODE[c]);
      }, [])
      .filter(Boolean);

    return uniq(questionCodes);
  }

  getUWQuestions(questionCodes: QuestionCode[]): UWQuestion[] {
    return questionCodes.reduce((acc: Array<UWQuestion>, qCode) => {
      return acc.concat(UW_QUESTIONS[qCode]);
    }, []);
  }

  removeLocation(index: number) {
    this.removeLocationFromForm(index);
    this.syncAllSteps();
  }

  addLocation() {
    this.addLocationForm(false);
    this.syncAllSteps();
  }

  addBuilding(locationIndex: number): UntypedFormGroup {
    const building = this.createBuilding();
    const buildingsControl = this.get<UntypedFormArray>(['locations', locationIndex, 'buildings']);

    const locationStateControl = this.get<UntypedFormArray>([
      'locations',
      locationIndex,
      'locationDetails',
      'state',
    ]);
    buildingsControl.push(building);
    this.newBuildingSubscriptions(building, locationStateControl);
    return building;
  }

  removeAllLocations() {
    this.removeAll('policyInfo.locationAddresses');
    this.removeAll('locations');
  }

  removeAllAdditionalInsuredBusinesses() {
    this.removeAll('additionalCoverages.additionalInsuredBusinesses');
  }

  removeAllWaiverOfSubrogations() {
    this.removeAll('additionalCoverages.waiversOfSubrogation');
  }

  enableDisableContractorsV1(enabled: boolean) {
    const eachJobLimitControl = getControl(this.form, 'liabilityCoverages.eachCoveredJobSiteLimit');
    const propertyInTransitControl = getControl(
      this.form,
      'liabilityCoverages.propertyInTransitLimit'
    );

    enableDisableControl(eachJobLimitControl, enabled);
    enableDisableControl(propertyInTransitControl, enabled);
  }

  enableDisableContractorsV2(enabled: boolean) {
    const installationLimitControl = getControl(this.form, 'liabilityCoverages.installationLimit');
    const toolsBlanketLimitControl = getControl(this.form, 'liabilityCoverages.toolsBlanketLimit');
    const toolsPerItemLimitControl = getControl(this.form, 'liabilityCoverages.toolsPerItemLimit');

    enableDisableControl(installationLimitControl, enabled);
    enableDisableControl(toolsBlanketLimitControl, enabled);
    enableDisableControl(toolsPerItemLimitControl, enabled);
  }

  updateBOPEligibilityUWQuestions(bopExposure: BopExposureInfo) {
    const questionsToEnableDisable = {
      wholesalerAndMercantileOperations: bopExposure.locations.some((location) =>
        location.buildings.some(
          (building) =>
            !building.exposure.lessorsRisk &&
            ['Wholesaler', 'Mercantile'].includes(building.exposure.businessType)
        )
      ),
      liveEntertainmentActivities: bopExposure.locations.some((location) =>
        location.buildings.some(
          (building) =>
            !building.exposure.lessorsRisk &&
            ['RestaurantCasualDining', 'RestaurantFineDining'].includes(
              building.exposure.businessType
            )
        )
      ),
      nailSalonBeautyParlorBarberShopServices: bopExposure.locations.some((location) =>
        location.buildings.some(
          (building) =>
            !building.exposure.lessorsRisk &&
            [NAIL_SALON_CLASS_CODE, BARBER_SHOP_CLASS_CODE].includes(
              building.exposure.classification.code?.code || ''
            )
        )
      ),
      totalSubcontractorCostsPercentage: bopExposure.locations.some((location) =>
        location.buildings.some(
          (building) =>
            !building.exposure.lessorsRisk && building.exposure.businessType === 'Contractor'
        )
      ),
    };
    const eligibilityUWQuestionsGroup = getFormGroup(
      this.form,
      'liabilityCoverages.eligibilityUWQuestions'
    );
    const isEligibleBOPProduct = bopExposure.bopVersion === 2 || this.meToo();
    Object.entries(questionsToEnableDisable).forEach(([question, enabled]) => {
      const questionControl = getControl(eligibilityUWQuestionsGroup, question);
      enableDisableControl(questionControl, enabled && isEligibleBOPProduct);
    });
  }

  updateBOPUnderwritingQuestions(bopExposure: BopExposureInfo) {
    // Underwriting has requested that we add a few questions when a specific classcode is selected.
    const plumberClassCodesWithUWQuestion = ['75781', '75791', '75821', '75811'];
    const classCodesWithSmallDeviceUWQuestion = ['57224', '57326', '50925'];
    const allClassifications = this.getAllClassificationCodes(bopExposure, true);
    const hasUWPlumberClassification = allClassifications.some((classification) =>
      plumberClassCodesWithUWQuestion.includes(classification)
    );

    // We care if ANY location has a AOP deductible that is < 2500.
    const hasLocationWithQualifyingDeductible = bopExposure.locations.some((location) => {
      return Number(location.locationDetails.propertyDeductible) < 2500;
    });
    // We want to find the total square footage of all locations and buildings
    const totalSquareFootage = bopExposure.locations.reduce((sum, location) => {
      const buildingSquareFootage = location.buildings.reduce((buildingSum, building) => {
        return buildingSum + parseMaskedInt(building.exposure.squareFootage);
      }, 0);
      return sum + buildingSquareFootage;
    }, 0);
    const hasLRO = locationsHaveLroBuildings(bopExposure.locations);

    const equipmentOvernightFormControl = getControl(
      this.form,
      'liabilityCoverages.equipmentLeftInVehicle'
    );

    const stressTestedLinesFormControl = getControl(
      this.form,
      'liabilityCoverages.stressTestsWaterLines'
    );

    const employeeHandbookFormControl = getControl(
      this.form,
      'liabilityCoverages.employeeHandbook'
    );

    const janitorialServicesFormControl = getControl(
      this.form,
      'liabilityCoverages.janitorialServices'
    );
    if (bopExposure.bopVersion === 2) {
      bopExposure.locations.forEach((location, locationIndex) => {
        location.buildings.forEach((building, buildingIndex) => {
          if (!building.exposure.classification.code) {
            return;
          }

          const personalDeviceSaleControl = getControl(
            this.form,
            `locations.${locationIndex}.buildings.${buildingIndex}.exposure.percentSalesPersonalDevices`
          );
          const hasPersonalDeviceClassCode = classCodesWithSmallDeviceUWQuestion.includes(
            building.exposure.classification.code.code
          );

          enableDisableControl(
            personalDeviceSaleControl,
            hasPersonalDeviceClassCode && !building.exposure.lessorsRisk
          );
        });
      });
    }

    enableDisableControl(
      equipmentOvernightFormControl,
      bopExposure.bopVersion === 2 &&
        hasUWPlumberClassification &&
        hasLocationWithQualifyingDeductible
    );

    // For this question we do not care about the deductible.
    enableDisableControl(
      stressTestedLinesFormControl,
      bopExposure.bopVersion === 2 && hasUWPlumberClassification
    );

    enableDisableControl(
      employeeHandbookFormControl,
      bopExposure.bopVersion === 2 &&
        totalSquareFootage < SMALL_ACCOUNT_SQUARE_FOOTAGE_MAXIMUM &&
        !hasLRO
    );

    enableDisableControl(
      janitorialServicesFormControl,
      bopExposure.bopVersion === 2 &&
        totalSquareFootage < SMALL_ACCOUNT_SQUARE_FOOTAGE_MAXIMUM &&
        !hasLRO
    );
  }

  updateContractorFields(bopExposure: BopExposureInfo) {
    const snowPlowControl = getControl(
      this.form,
      'liabilityCoverages.acceptSnowPlowCompletedOpsCoverage'
    );
    const isContractor = bopExposure.locations.some((location) => {
      return location.buildings.some((building) => {
        return (
          !building.exposure.lessorsRisk &&
          building.exposure.businessType === AVAILABLE_BUSINESS_TYPES.Contractor
        );
      });
    });

    // NOTE: Snow Plow / Completed Ops is available for all contractors EXCEPT NY (BOP-258)
    if (isContractor && bopExposure.baseState !== 'NY') {
      enableDisableControl(snowPlowControl, true);
    } else {
      enableDisableControl(snowPlowControl, false);
    }

    if (
      isContractor &&
      bopExposure.bopVersion === 2 &&
      !KEEP_V1_CONTRACTOR_CONTROL_STATES.includes(bopExposure.baseState || '')
    ) {
      this.enableDisableContractorsV1(false);
      this.enableDisableContractorsV2(true);
    } else if (isContractor) {
      // Contractors V1 Controls
      this.enableDisableContractorsV1(true);
      this.enableDisableContractorsV2(false);
    } else {
      // Disable all Contractors' Controls
      this.enableDisableContractorsV1(false);
      this.enableDisableContractorsV2(false);
    }
  }

  isV2ContractorEnhancement() {
    if (this.bopVersion() === 1) {
      return false;
    }
    const locations = this.form.value.locations;
    if (locations.length === 0) {
      return false;
    }
    // MPC only checks if first building is LRO when deciding if it should add the contractor's enhancement,
    // so we want to be consistent with that
    if (locations[0].buildings[0].exposure.lessorsRisk) {
      return false;
    }
    return locations.some((location: BopLocation) => {
      return location.buildings.some((building: BopBuilding) => {
        return building.exposure.businessType === AVAILABLE_BUSINESS_TYPES.Contractor;
      });
    });
  }

  updateCyberLiability(bopExposure: BopExposureInfo) {
    const baseState = bopExposure.baseState;
    const cyberLiabilityControl = getControl(
      this.form,
      'additionalCoverages.cyberLiabilityCoverage.selectedCyberCoverage'
    );

    if (!baseState) {
      return;
    }

    const buildingCanHaveCyber = (building: BopBuilding) => {
      if (!building.exposure.classification.code) {
        return true;
      }
      return !EXCLUDED_V2_CYBER_CODES.includes(building.exposure.classification.code.code);
    };

    const classificationCanHaveCyber =
      bopExposure.bopVersion === 1 ||
      bopExposure.locations.every((location) => {
        return location.buildings.every(buildingCanHaveCyber);
      });

    enableDisableControl(cyberLiabilityControl, classificationCanHaveCyber);
  }

  /**
   * Sets up a subscription for cyber endorsement controls to update default aggregate and first party limits based on state differences
   * and automatically selects the relevant deductible for a limit.
   */
  setupCyberOptionsUpdateSubscription(controls: {
    aggregateLimitControl: FormControl;
    firstPartyLimitControl: FormControl;
    deductibleControl: FormControl;
    bopVersionControl: FormControl;
    baseStateControl: FormControl;
  }) {
    const {
      aggregateLimitControl,
      firstPartyLimitControl,
      deductibleControl,
      bopVersionControl,
      baseStateControl,
    } = controls;
    combineLatest([
      aggregateLimitControl.valueChanges.pipe(startWith(aggregateLimitControl.value)),
      firstPartyLimitControl.valueChanges.pipe(startWith(firstPartyLimitControl.value)),
      bopVersionControl.valueChanges.pipe(startWith(bopVersionControl.value)),
      baseStateControl.valueChanges.pipe(startWith(baseStateControl.value)),
    ])
      .pipe(distinctUntilChanged((prev, curr) => isEqual(prev, curr)))
      .subscribe(([aggregateLimit, firstPartyLimit, bopVersion, baseState]) => {
        if (!baseState || bopVersion === 1) {
          return;
        }
        const isSplitLimitState = BOP_V2_CYBER_SPLIT_STATES.includes(baseState);
        const cyberOptions = BOP_V2_CYBER_OPTIONS?.[baseState] || BOP_V2_CYBER_OPTIONS.default;
        // Ensure that default aggregate limit is valid for this state
        if (cyberOptions.aggregateLimitOptions) {
          const currentOptionValid = cyberOptions.aggregateLimitOptions.some(
            (option) => option.limit === aggregateLimit
          );
          if (!currentOptionValid) {
            // If the current option is not valid, patch the control to default to the lowest valid limit.
            aggregateLimitControl.patchValue(cyberOptions.aggregateLimitOptions[0].limit);
          }
        }
        // Ensure that default first party limit is valid for this state
        if (cyberOptions.firstPartyLimitOptions) {
          const currentOptionValid = cyberOptions.firstPartyLimitOptions.some(
            (option) => option.limit === firstPartyLimit
          );
          if (!currentOptionValid) {
            // If the current option is not valid, patch the control to default to the lowest valid limit.
            firstPartyLimitControl.patchValue(cyberOptions.firstPartyLimitOptions[0].limit);
          }
        }

        // Update the deductible (not selected by a user). Specific deductibles are coupled with each limit.
        let deductibleToUse: number | undefined;
        if (isSplitLimitState) {
          deductibleToUse = cyberOptions.firstPartyLimitOptions?.find(
            (limit) => limit.limit === firstPartyLimit
          )?.deductible;
        } else {
          deductibleToUse = cyberOptions.aggregateLimitOptions?.find(
            (limit) => limit.limit === aggregateLimit
          )?.deductible;
        }

        if (deductibleToUse) {
          deductibleControl.patchValue(deductibleToUse);
        }
      });
  }

  updateEBLC(bopExposure: BopExposureInfo) {
    const baseState = bopExposure.baseState;
    const eblcControl: UntypedFormControl = this.get(
      'liabilityCoverages.employeeBenefitsLiabilityCoverage.optedIn'
    );

    if (!baseState) {
      return;
    }
    if (baseState === 'NY' && !this.meToo()) {
      enableDisableControl(eblcControl, false);
    } else {
      enableDisableControl(eblcControl, true);
    }
  }

  updateEPLI(bopExposure: BopExposureInfo) {
    const baseState = bopExposure.baseState;
    const bopVersion = bopExposure.bopVersion === 2 ? 2 : 1;
    const epliControl: UntypedFormControl = this.get(
      'additionalCoverages.employmentRelatedPracticesLiabilityCoverage.optedIn'
    );

    if (!baseState) {
      return;
    }

    const classificationCannotHaveEPLI = some(bopExposure.locations, (location) => {
      if (!location.buildings[0].exposure.classification.code) {
        return false;
      }
      return (
        location.buildings[0].exposure.lessorsRisk === false &&
        EPLI_EXCEPTIONS.includes(location.buildings[0].exposure.classification.code.code)
      );
    });

    const isBopV2StateIneligibleForEPLI =
      bopVersion === 2 && BOP_V2_EPLI_INELIGIBLE_STATES.includes(baseState);

    if (classificationCannotHaveEPLI || isBopV2StateIneligibleForEPLI || this.meToo()) {
      enableDisableControl(epliControl, false);
    } else {
      enableDisableControl(epliControl, true);
    }

    this.updateEPLIV1Controls(bopVersion);
    this.updateEPLIV2Controls(bopVersion, bopExposure.locations.length, baseState);
  }

  updateEPLIV1Controls(bopVersion: 1 | 2) {
    const retroactiveDateControl: UntypedFormControl = this.get(
      'additionalCoverages.employmentRelatedPracticesLiabilityCoverage.retroactiveDate'
    );
    const aggregateLimitControl: UntypedFormControl = this.get(
      'additionalCoverages.employmentRelatedPracticesLiabilityCoverage.aggregateLimit'
    );
    const deductibleControl: UntypedFormControl = this.get(
      'additionalCoverages.employmentRelatedPracticesLiabilityCoverage.deductible'
    );
    const eachEmploymentWrongfulActControl: UntypedFormControl = this.get(
      'additionalCoverages.employmentRelatedPracticesLiabilityCoverage.eachEmploymentWrongfulActLimit'
    );

    if (bopVersion === 2) {
      enableDisableControl(retroactiveDateControl, false);
      enableDisableControl(aggregateLimitControl, false);
      enableDisableControl(deductibleControl, false);
      enableDisableControl(eachEmploymentWrongfulActControl, false);
    } else {
      enableDisableControl(retroactiveDateControl, true);
      enableDisableControl(aggregateLimitControl, true);
      enableDisableControl(deductibleControl, true);
      enableDisableControl(eachEmploymentWrongfulActControl, true);
    }
  }

  updateEPLIV2Controls(bopVersion: 1 | 2, numLocations: number, baseState: string) {
    const aggregateLimitV2: UntypedFormControl = this.get(
      'additionalCoverages.employmentRelatedPracticesLiabilityCoverage.aggregateLimitV2'
    );
    const defenseLimitV2: UntypedFormControl = this.get(
      'additionalCoverages.employmentRelatedPracticesLiabilityCoverage.defenseLimitV2'
    );
    const deductibleV2: UntypedFormControl = this.get(
      'additionalCoverages.employmentRelatedPracticesLiabilityCoverage.deductibleV2'
    );
    const perLocationPartTimeEmployees: UntypedFormArray = this.get(
      'additionalCoverages.employmentRelatedPracticesLiabilityCoverage.perLocationPartTimeEmployees'
    );

    if (bopVersion === 2) {
      enableDisableControl(deductibleV2, true);
      enableDisableControl(perLocationPartTimeEmployees, true);

      if (['LA', 'MT'].includes(baseState)) {
        // Split limit state, LA, MT
        enableDisableControl(aggregateLimitV2, false);
        enableDisableControl(defenseLimitV2, true);
        this.epliOptions.defenseLimitV2 = BOP_V2_EPLI_DEFENSE_LIMIT_OPTIONS_HIGH;
        clampControl(defenseLimitV2, BOP_V2_EPLI_SPLIT_LIMITS_HIGH);
      } else if (['NM', 'AR', 'VT'].includes(baseState)) {
        // Split limit state AR, NM, VT
        enableDisableControl(aggregateLimitV2, false);
        enableDisableControl(defenseLimitV2, true);
        this.epliOptions.defenseLimitV2 = BOP_V2_EPLI_DEFENSE_LIMIT_OPTIONS;
        clampControl(defenseLimitV2, BOP_V2_EPLI_SPLIT_LIMITS_NORMAL);
      } else if (['MN', 'NH', 'NY', 'ND'].includes(baseState)) {
        // Aggregate state, MN, NH, NY, ND
        enableDisableControl(aggregateLimitV2, true);
        enableDisableControl(defenseLimitV2, false);
        this.epliOptions.aggregateLimitV2 = BOP_V2_EPLI_AGG_LIMIT_OPTIONS_HIGH;
        clampControl(aggregateLimitV2, BOP_V2_EPLI_AGG_LIMITS_HIGH);
      } else {
        // Aggregate state, rest
        enableDisableControl(aggregateLimitV2, true);
        enableDisableControl(defenseLimitV2, false);
        this.epliOptions.aggregateLimitV2 = BOP_V2_EPLI_AGG_LIMIT_OPTIONS;
        clampControl(aggregateLimitV2, BOP_V2_EPLI_AGG_LIMITS_NORMAL);
      }

      while (perLocationPartTimeEmployees.controls.length < numLocations) {
        perLocationPartTimeEmployees.controls.push(new UntypedFormControl(0));
      }
      while (perLocationPartTimeEmployees.controls.length > numLocations) {
        perLocationPartTimeEmployees.controls.pop();
      }
    } else {
      enableDisableControl(aggregateLimitV2, false);
      enableDisableControl(defenseLimitV2, false);
      enableDisableControl(deductibleV2, false);
      enableDisableControl(perLocationPartTimeEmployees, false);
    }
  }

  updateHNOA(bopExposure: BopExposureInfo) {
    this.showHnoaUwQuestions = bopExposure.locations.every(
      (location: BopLocation) =>
        HNOA_AVAILABLE_STATES.includes(location.locationDetails.state) &&
        location.buildings.some((building: BopBuilding) => {
          if (!building.exposure.classification.code) {
            return false;
          }
          return HNOA_AVAILABLE_CLASS_CODES.includes(building.exposure.classification.code.code);
        })
    );
    const shouldEnable =
      this.getExposureFieldValue(bopExposure, 'lessorsRisk') ||
      !HNOA_NOT_AVAILABLE_BUSINESS_TYPES.includes(
        this.getExposureFieldValue(bopExposure, 'businessType')
      ) ||
      this.showHnoaUwQuestions;
    const hnoaControl = getControl(this.form, 'liabilityCoverages.acceptHiredNonOwnedAutoCoverage');
    enableDisableControl(hnoaControl, shouldEnable);
    const numDriversControl = getControl(this.form, 'liabilityCoverages.hnoaNumberOfDrivers');
    enableDisableControl(
      numDriversControl,
      bopExposure.bopVersion === 2 && hnoaControl.value === true
    );
    const shouldShowHnoaControl =
      bopExposure.bopVersion === 2 && this.showHnoaUwQuestions && hnoaControl.value === true;
    hnoaUwQuestions.forEach((ctrlName) => {
      const ctrl = getControl(this.form, `liabilityCoverages.${ctrlName}`);
      enableDisableControl(ctrl, shouldShowHnoaControl);
    });
  }

  updateXSQuestions(bopExposure: BopExposureInfo) {
    this.uwQuestions = this.getUWQuestions(
      this.getUWQuestionCodes(this.getAllClassificationCodes(bopExposure))
    );
    const excessLiabilityUwQuestions: UntypedFormGroup = <UntypedFormGroup>(
      this.form.get('excessLiability.uwQuestions')
    );

    Object.keys(excessLiabilityUwQuestions).forEach((excessLiabilityUwQuestion) => {
      if (
        this.uwQuestions.some((uwQuestion) => {
          return uwQuestion.questionID === excessLiabilityUwQuestion;
        })
      ) {
        return;
      }
      excessLiabilityUwQuestions.removeControl(excessLiabilityUwQuestion);
    });

    this.uwQuestions.forEach((q) => {
      excessLiabilityUwQuestions.addControl(
        q.questionID,
        new UntypedFormControl(null, Validators.required)
      );
    });
  }

  updateProfessionalLiabilities(bopExposure: BopExposureInfo) {
    const allClassifications = this.getAllClassificationCodes(bopExposure);
    const availableCoverages = uniq(
      flatMap(allClassifications, (classification) => {
        return AVAILABLE_LIABILITIES_BY_BUILDING_CLASSIFICATION[classification];
      })
    );

    let hasWackyFLCase = false;

    ALL_PROF_LIABILITIES.forEach((liabilityFormKey: OperationsCoverage) => {
      const liabilityControl = getControl(this.form, `liabilityCoverages.${liabilityFormKey}`);

      let shouldEnable = false;
      if (availableCoverages.includes(liabilityFormKey)) {
        shouldEnable = true;

        // Wacky exception for FL Beauty Salons - https://hamiltonusa.atlassian.net/browse/BOP-1535
        // https://www.dropbox.com/s/h8znyprj5o52nkh/barber_professional_liability.png?dl=0
        if (
          (liabilityFormKey === OperationsCoverage.BEAUTY_SALON ||
            liabilityFormKey === OperationsCoverage.BARBER_SHOP_AND_HAIR_SALON) &&
          bopExposure.baseState === 'FL'
        ) {
          hasWackyFLCase = true;
          return;
        }
      }

      enableDisableControl(liabilityControl, shouldEnable);
    });

    if (hasWackyFLCase) {
      const barberControl = getControl(
        this.form,
        `liabilityCoverages.${OperationsCoverage.BARBER_SHOP_AND_HAIR_SALON}`
      );
      const beautyControl = getControl(
        this.form,
        `liabilityCoverages.${OperationsCoverage.BEAUTY_SALON}`
      );

      enableDisableControl(barberControl, true);
      enableDisableControl(beautyControl, false);
    }
  }

  updateBuildingLimit(bopExposure: BopExposureInfo) {
    bopExposure.locations.forEach((location: BopLocation, locationIndex: number) => {
      location.buildings.forEach((building: BopBuilding, buildingIndex: number) => {
        const exposure = this.get<UntypedFormGroup>([
          'locations',
          locationIndex,
          'buildings',
          buildingIndex,
          'exposure',
        ]);
        const buildingLimitField = getControl(exposure, 'buildingLimit');
        if (buildingLimitField) {
          const disableBuildingLimit =
            BUILDING_LIMIT_NOT_AVAILABLE_BUSINESS_TYPES.includes(building.exposure.businessType) &&
            !building.exposure.lessorsRisk;
          enableDisableControl(buildingLimitField, !disableBuildingLimit);
        }
      });
    });
  }

  updateBuildingLROStep(bopExposure: BopExposureInfo) {
    // TODO(NY) - Remove feature flag once this feature is rolled out to all of prod for 2 weeks.
    if (!this.lessorsRiskPageFeatureFlaggedOn) {
      return;
    }

    bopExposure.locations.forEach((location: BopLocation, locationIndex: number) => {
      location.buildings.forEach((building: BopBuilding, buildingIndex: number) => {
        const lroFormGroup = this.get<UntypedFormGroup>([
          'locations',
          locationIndex,
          'buildings',
          buildingIndex,
          'lessorsRisk',
        ]);
        const nullIfNoChange = enableDisableControl(lroFormGroup, building.exposure.lessorsRisk);

        if (nullIfNoChange !== null) {
          // The LRO step is an optional step and if the form is toggled we want to re-sync.
          this.syncAllSteps();
        }
      });
    });
  }

  updateWaiverOfSubrogation(bopExposure: BopExposureInfo) {
    const waiversOfSubrogation = this.get<UntypedFormArray>(
      'additionalCoverages.waiversOfSubrogation'
    );
    const hasWaiversOfSubrogation = getControl(
      this.form,
      'additionalCoverages.hasWaiversOfSubrogation'
    );
    const enableField = bopExposure.bopVersion === 1 && hasWaiversOfSubrogation.value === true;
    enableDisableControl(waiversOfSubrogation, enableField);
    if (enableField && waiversOfSubrogation.length === 0) {
      this.addWaiverOfSubrogation(waiversOfSubrogation);
    }
    if (this.isV2ContractorEnhancement()) {
      // WoS is included by default for contractor's enhancements
      hasWaiversOfSubrogation.patchValue(true);
    }
  }

  getValue(): BopQuoteFormValue {
    return this.form.value;
  }

  getValueWithoutStandaloneCyberFields(): BopQuoteFormValue {
    const cyberLiabilityWithoutUpsellFields = {
      ...omit(this.getValue().additionalCoverages.cyberLiabilityCoverage, [
        'productType',
        CoalitionCyberQuestion.COMPANY_INDUSTRY_ID,
        CoalitionCyberQuestion.HAS_TECH_EO,
        CoalitionCyberQuestion.COMPANY_REVENUE,
        CoalitionCyberQuestion.COMPANY_GROSS_PROFIT_NET_REVENUE,
        CoalitionCyberQuestion.AGGREGATE_LIMIT,
        CoalitionCyberQuestion.DEFAULT_RETENTION,
        CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS,
        CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_COUNT,
        CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT,
        CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT_MORE_THAN_500K,
        CoalitionCyberQuestion.PRIOR_CLAIMS,
        CoalitionCyberQuestion.PRIOR_CLAIMS_EXPLANATION,
        CoalitionCyberQuestion.DUAL_CONTROL_5K,
        CoalitionCyberQuestion.ADMINISTRATIVE_DUAL_CONTROL,
        CoalitionCyberQuestion.MFA_EMAIL,
        CoalitionCyberQuestion.MFA_REMOTE_ACCESS,
        CoalitionCyberQuestion.MFA_VPN,
        CoalitionCyberQuestion.MFA_OTHER_PRIVILEGED_ACCOUNTS,
        CoalitionCyberQuestion.ENGAGED_INDUSTRIES,
      ]),
    } as BopQuoteFormValue['additionalCoverages']['cyberLiabilityCoverage'];

    // If the standalone product is selected, we mark the optedIn value as 'false'
    // so that we do not attempt to add the coverage as part of the BOP policy.
    if (this.isCoalitionCyberSelected()) {
      cyberLiabilityWithoutUpsellFields.optedIn = false;
      cyberLiabilityWithoutUpsellFields.selectedCyberCoverage = 'coalition-cyber';
    }

    return {
      ...this.getValue(),
      additionalCoverages: {
        ...this.getValue().additionalCoverages,
        cyberLiabilityCoverage: cyberLiabilityWithoutUpsellFields,
      },
    };
  }

  // FIRST classification only [!]
  private getExposureFieldValue(bopExposure: BopExposureInfo, exposureField: string) {
    return lodashGet(bopExposure, `locations.0.buildings.0.exposure.${exposureField}`);
  }

  private getAllClassificationCodes(bopExposure: BopExposureInfo, filterLessorsRiskCodes = false) {
    const allClassifications: string[] = [];
    bopExposure.locations.forEach((location: BopLocation) => {
      location.buildings.forEach((building: BopBuilding) => {
        if (!building.exposure.classification.code) {
          return;
        }
        const classCode = building.exposure.classification.code.code;
        if (!filterLessorsRiskCodes || (filterLessorsRiskCodes && !building.exposure.lessorsRisk)) {
          allClassifications.push(classCode);
        }
      });
    });
    return allClassifications;
  }

  private setCyberRetroDateAsEffectiveDate(value: string) {
    getControl(this.form, 'additionalCoverages.cyberLiabilityCoverage.retroactiveDate').patchValue(
      value
    );
  }

  updateLiquorControls(bopExposureInfo: BopExposureInfo) {
    const locations = bopExposureInfo.locations;
    const baseState: any = bopExposureInfo.baseState;

    const liquorControl = getControl(this.form, 'liabilityCoverages.liquorLiability.optedIn');

    const liquorEachCauseLimit = getControl(
      this.form,
      'liabilityCoverages.liquorLiability.eachCommonCauseLimit'
    );
    const liquorAggregateControl = getControl(
      this.form,
      'liabilityCoverages.liquorLiability.aggregateLimit'
    );

    const liquorLiabilityLicenseControl = getControl(
      this.form,
      'liabilityCoverages.liquorLiability.liquorLicenseNumber'
    );

    const liquorLiabilityUWQuestionsControl = getControl(
      this.form,
      'liabilityCoverages.eligibilityUWQuestions.liquorLiabilityUWQuestions'
    );

    const allClassifications = flatMap(locations, (loc) => {
      return loc.buildings.map((building: BopBuilding) => building.exposure.classification);
    });

    const liquorCoverageAvailable =
      classificationsHaveLiquor(allClassifications) &&
      !LIQUOR_LIABILITY_COVERAGE_EXCLUSION_STATES.includes(baseState);

    const liquorDefaultsToYes = classificationsHaveLiquor(allClassifications, true);

    const liquorOptedIn = this.form.value?.liabilityCoverages?.liquorLiability?.optedIn || false;

    if (!liquorCoverageAvailable) {
      // Liquor coverage is not available
      enableDisableControl(liquorControl, false);
      enableDisableControl(liquorAggregateControl, false);
      enableDisableControl(liquorEachCauseLimit, false);
      enableDisableControl(liquorLiabilityLicenseControl, false);
      enableDisableControl(liquorLiabilityUWQuestionsControl, false);
      return;
    } else {
      // Liquor Coverage is Available
      const modified = enableDisableControl(liquorControl, true);
      enableDisableControl(liquorAggregateControl, true);

      enableDisableControl(
        liquorLiabilityUWQuestionsControl,
        this.shouldShowLiquorLiabilityUWQuestions(liquorOptedIn, locations)
      );

      enableDisableControl(
        liquorLiabilityLicenseControl,
        this.shouldShowLiquorLicense(liquorOptedIn, locations)
      );

      // when liquor license is shown but UW questions are not, do not require liquor license number
      const shouldNotRequireLiquorLicenseNumber =
        this.shouldShowLiquorLicense(liquorOptedIn, locations) &&
        !this.shouldShowLiquorLiabilityUWQuestions(liquorOptedIn, locations);
      if (shouldNotRequireLiquorLicenseNumber) {
        liquorLiabilityLicenseControl.setValidators([validateLiquorLicenseNumber]);
      }

      // Illinois doesn't collect ECC, so it's disabled in IL
      enableDisableControl(liquorEachCauseLimit, baseState !== 'IL');

      if (modified) {
        if (liquorDefaultsToYes) {
          // Liquor Coverage defaults to true for codes where liquor sales are required
          this.form.patchValue({
            liabilityCoverages: { liquorLiability: { optedIn: true } },
          });
        } else {
          this.form.patchValue({
            liabilityCoverages: { liquorLiability: { optedIn: false } },
          });
        }
      }
    }
  }

  shouldShowLiquorLiabilityUWQuestions(liquorOptedIn: boolean, locations: BopLocation[]): boolean {
    const allClassifications = flatMap(locations, (loc) => {
      return loc.buildings.map((building: BopBuilding) => building.exposure.classification);
    });

    const isAfterLiquorUWEffectiveDate = moment(
      this.form.value.policyInfo.effectiveDate
    ).isSameOrAfter(liquorUWEffectiveDate);

    const allHaveBYOCode = allClassifications.every((classification) => {
      return classificationHasByobAlcohol(classification.code);
    });

    const hasBeverageStoreCode = allClassifications.some((classification) => {
      return classificationsHaveBeverageStore(classification.code);
    });

    const hasLRO = locationsHaveLroBuildings(locations);

    return (
      liquorOptedIn &&
      isAfterLiquorUWEffectiveDate &&
      !hasLRO &&
      !allHaveBYOCode &&
      !hasBeverageStoreCode
    );
  }

  shouldShowLiquorLicense(liquorOptedIn: boolean, locations: BopLocation[]): boolean {
    const isAfterLiquorUWEffectiveDate = moment(
      this.form.value.policyInfo.effectiveDate
    ).isSameOrAfter(liquorUWEffectiveDate);

    const hasLRO = locationsHaveLroBuildings(locations);

    return liquorOptedIn && isAfterLiquorUWEffectiveDate && !hasLRO;
  }

  private removeAll(path: string | Array<string | number>) {
    const array = this.get<UntypedFormArray>(path);
    while (array.length > 0) {
      array.removeAt(0);
    }
  }

  epliDeductibleV2Validator() {
    const formSvc = this;
    return (control: UntypedFormControl): null | ValidationErrors => {
      if (Object.values(formSvc.epliOptions.deductibleV2).includes(control.value)) {
        return null;
      }
      return { invalidValue: 'Please select one of the available deductibles' };
    };
  }

  patchForm(
    bopFormInputs: DeepPartial<BopQuotePayload> | DeepPartial<BopQuoteFormValue> | null,
    excessFormInput?: BopQuotePayload['excessLiability'] | null
  ) {
    if (bopFormInputs) {
      // There are 4 FormArrays currently in the form. Each of these needs to be properly populated with FormGroups
      // so that the patch will patch correctly.

      // FormArray: form.locations
      const locations = bopFormInputs.locations || [];
      if (locations.length > 0) {
        this.removeAllLocations();
      }
      times(locations.length, () => this.addLocation());

      // FormArray: form.locations.buildings
      locations.forEach((loc: BopLocation, locIndex: number) => {
        // New locations default to having one building and at least one is required
        times(loc.buildings.length - 1, () => this.addBuilding(locIndex));
      });

      // Add as many LRO tenant form controls as needed to each building.
      locations.forEach((loc: BopLocation, locIndex: number) => {
        loc.buildings.forEach((building: BopBuilding, buildingIndex: number) => {
          if (building.lessorsRisk) {
            const lessorsriskTenants = building.lessorsRisk.lessorsRiskTenants;
            const rentRollFiles = building.lessorsRisk.rentRollFiles;
            // The first tenant control is already added when the building is created.
            times(lessorsriskTenants.length - 1, () => {
              const tenantFormArray = this.get<UntypedFormArray>(
                `locations.${locIndex}.buildings.${buildingIndex}.lessorsRisk.lessorsRiskTenants`
              );
              this.addLessorsRiskTenant(tenantFormArray);
            });

            // Create a placeholder rent roll file form control to be patched.
            times(rentRollFiles.length, () => {
              const rentRollFormArray = this.get<UntypedFormArray>(
                `locations.${locIndex}.buildings.${buildingIndex}.lessorsRisk.rentRollFiles`
              );
              this.addRentRollFile(rentRollFormArray);
            });
          }
        });
      });

      // FormArray: form.additionalCoverages.additionalInsuredBusinesses
      const formInputAdditionalInsureds = lodashGet(
        bopFormInputs,
        'additionalCoverages.additionalInsuredBusinesses',
        []
      );
      this.removeAllAdditionalInsuredBusinesses();
      formInputAdditionalInsureds.forEach(() => {
        this.addAdditionalInsuredBusiness(
          this.get<UntypedFormArray>('additionalCoverages.additionalInsuredBusinesses')
        );
      });

      // FormArray: form.policyInfo.locationAddresses
      // This is a copy of the Address data in form.locations.locationDetails which is the standard
      const locationAddresses: Address[] = locations.map((location) => {
        const completeAddress = { ...emptyAddress, ...lodashGet(location, 'locationDetails', {}) };
        return getAddress(completeAddress);
      });

      // FormArray: form.additionalCoverages.waiversOfSubrogation;
      const formWaiversOfSubrogation = lodashGet(
        bopFormInputs,
        'additionalCoverages.waiversOfSubrogation',
        []
      );
      this.removeAllWaiverOfSubrogations();
      formWaiversOfSubrogation.forEach(() => {
        this.addWaiverOfSubrogation(
          this.get<UntypedFormArray>('additionalCoverages.waiversOfSubrogation')
        );
      });

      // Note: patch values in same order as quote flow
      // Guidelines step
      this.form.patchValue({
        guidelines: true,
      });
      // policy-info step
      this.form.patchValue({
        policyInfo: {
          ...bopFormInputs.policyInfo,
          locationAddresses,
        },
      });
      // location+building steps
      this.form.patchValue({
        locations: locations,
      });
      // liability-coverages
      if (bopFormInputs.liabilityCoverages) {
        this.form.patchValue({
          liabilityCoverages: bopFormInputs.liabilityCoverages,
        });
      }
      // additional-coverages
      this.form.patchValue({
        additionalCoverages: bopFormInputs.additionalCoverages,
      });
      // excess-liability
      this.form.patchValue({
        excessLiabilityOptIn: !!excessFormInput,
        excessLiability: excessFormInput ? excessFormInput : bopFormInputs.excessLiability,
      });
    }
  }

  updateFloridaAgentFields(bopExposure: BopExposureInfo) {
    const agentNameFormControl = getControl(this.form, 'policyInfo.agentName');
    const agentLicenseNumFormControl = getControl(this.form, 'policyInfo.agentLicenseNumber');

    // This information is only required for Florida base state quotes and only on quote submissions.
    enableDisableControl(agentNameFormControl, bopExposure.baseState === 'FL');
    enableDisableControl(agentLicenseNumFormControl, bopExposure.baseState === 'FL');
  }

  // Methods for Cyber upsell
  updateQuestionEnablementFlags(flags: CyberQuestionEnablementFlags) {
    this.cyberIndustryFlagsSubject.next(flags);
  }

  isCoalitionCyberSelected() {
    const productTypeControl = this.get(
      'additionalCoverages.cyberLiabilityCoverage.selectedCyberCoverage'
    );

    return (
      !!productTypeControl &&
      productTypeControl.enabled &&
      productTypeControl.value === 'coalition-cyber'
    );
  }

  getCyberAdmittedEarlyDeclineReasons(): UpsellEarlyDeclineKey[] {
    const cyberCoverageGroup = getFormGroup(
      this.form,
      'additionalCoverages.cyberLiabilityCoverage'
    );

    const companyRevenueControl = getControl(
      cyberCoverageGroup,
      CoalitionCyberQuestion.COMPANY_REVENUE
    );
    const hasTechEoControl = getControl(cyberCoverageGroup, CoalitionCyberQuestion.HAS_TECH_EO);

    const earlyDeclineReasons: UpsellEarlyDeclineKey[] = [];

    if (
      companyRevenueControl.enabled &&
      parseMoney(companyRevenueControl.value) > ADMITTED_MAX_REVENUE
    ) {
      earlyDeclineReasons.push('exceedsMaxRevenue');
    }
    if (hasTechEoControl.enabled && hasTechEoControl.value === 'No') {
      earlyDeclineReasons.push('techEORequired');
    }

    return earlyDeclineReasons;
  }

  getStandaloneCyberFields(): CoalitionCyberFormDataFormatted {
    const cyberCoverageGroup = getFormGroup(
      this.form,
      'additionalCoverages.cyberLiabilityCoverage'
    );

    const cyberPayload: CoalitionCyberFormDataRaw = pick(cyberCoverageGroup.value, [
      CoalitionCyberQuestion.HAS_TECH_EO,
      CoalitionCyberQuestion.COMPANY_REVENUE,
      CoalitionCyberQuestion.COMPANY_GROSS_PROFIT_NET_REVENUE,
      CoalitionCyberQuestion.AGGREGATE_LIMIT,
      CoalitionCyberQuestion.DEFAULT_RETENTION,
      CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS,
      CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_COUNT,
      CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT,
      CoalitionCyberQuestion.AWARE_OF_PRIOR_CLAIMS_AMOUNT_MORE_THAN_500K,
      CoalitionCyberQuestion.PRIOR_CLAIMS,
      CoalitionCyberQuestion.PRIOR_CLAIMS_EXPLANATION,
      CoalitionCyberQuestion.DUAL_CONTROL_5K,
      CoalitionCyberQuestion.ADMINISTRATIVE_DUAL_CONTROL,
      CoalitionCyberQuestion.MFA_EMAIL,
      CoalitionCyberQuestion.MFA_REMOTE_ACCESS,
      CoalitionCyberQuestion.MFA_VPN,
      CoalitionCyberQuestion.MFA_OTHER_PRIVILEGED_ACCOUNTS,
      CoalitionCyberQuestion.ENGAGED_INDUSTRIES,
    ]);

    const naicsCode = this.naicsCodeSubject.getValue();
    if (naicsCode) {
      cyberPayload[NAICS_CODE] = naicsCode;
    } else {
      cyberPayload.company_industry_id =
        cyberCoverageGroup.value[CoalitionCyberQuestion.COMPANY_INDUSTRY_ID];
    }

    const bopQuoteForm: BopQuoteFormValue = this.form.value;

    cyberPayload.address = pick(bopQuoteForm.locations[0].locationDetails, [
      'addressLine1',
      'addressLine2',
      'city',
      'state',
      'zip',
    ]);
    // For upsell, submit with no domain
    cyberPayload.domain_names = '';
    cyberPayload.effective_date = bopQuoteForm.policyInfo.effectiveDate;

    cyberPayload.company_employee_count = getEmployeeCountValue(bopQuoteForm.locations);

    const aggregateLimit = getControl(
      cyberCoverageGroup,
      CoalitionCyberQuestion.AGGREGATE_LIMIT
    ).value;
    // Coverages are selected based on which aggregate limit the user selected:
    // * Essential bundle for 100K limit
    // * Most Popular bundle for 250K limit
    const { first_party_coverages, third_party_coverages } =
      aggregateLimit === ESSENTIAL_AGGREGATE_LIMIT_INDEX
        ? ESSENTIAL_COVERAGES
        : MOST_POPULAR_COVERAGES;

    // Since we are only supporting Admitted quotes, we must not include Surplus-specific coverages
    const NON_ADMITTED_COVERAGES = [
      FirstPartyCoverageNestedQuestion.BREACH_RESPONSE,
      FirstPartyCoverageNestedQuestion.TECH_EO,
    ];

    cyberPayload.first_party_coverages = omit(
      first_party_coverages,
      NON_ADMITTED_COVERAGES
    ) as Record<FirstPartyCoverageNestedQuestion, boolean>;
    cyberPayload.third_party_coverages = third_party_coverages;

    // Use default Underwriting question answers
    if (parseMoney(cyberPayload.company_revenue as string) < 1000000) {
      entries(CYBER_DEFAULT_UNDERWRITING_QUESTIONS_FOR_REVENUES_UNDER_1M).forEach(([key, val]) => {
        cyberPayload[key as CoalitionCyberQuestion] = val;
      });
    }

    const formData = formatFormFieldValues(cyberPayload);
    // Remove coverages that are invalid based on the industry flags
    const industryFlags = this.cyberIndustryFlagsSubject.getValue();
    if (industryFlags) {
      return removeIneligibleCyberCoverages(formData, industryFlags);
    }

    return formData;
  }
  // END Methods for Cyber upsell

  debugSaveFormData() {
    window.localStorage.setItem('BOPFormData', JSON.stringify(this.form.value));
    window.localStorage.setItem(
      'BOPStepData',
      JSON.stringify({
        steps: this.steps,
        currentStep: this.currentStep,
      })
    );
  }

  debugLoadFormData() {
    const formDataStorage = window.localStorage.getItem('BOPFormData');
    const stepDataStorage = window.localStorage.getItem('BOPStepData');

    if (!formDataStorage || !stepDataStorage) {
      return;
    }

    const formData = JSON.parse(formDataStorage);
    const stepData = JSON.parse(stepDataStorage);

    this.form.patchValue(formData);

    this.steps = stepData.steps;

    const currentStep = this.steps.find(
      (step: RouteFormStep) => step.slug === stepData.currentStep.slug
    );
    if (currentStep) {
      this.currentStep = currentStep;
    }
  }
}
