import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of as observableOf, catchError, Observable } from 'rxjs';
import { API_V4_BASE } from '../../constants';
import { SentryService } from '../../core/services/sentry.service';

export type PreferenceLevel = '1' | '2' | '3' | '4';
export interface PreferenceLevelResponse {
  preferenceLevel: PreferenceLevel;
}

@Injectable()
export class PreferenceService {
  constructor(private http: HttpClient, private sentryService: SentryService) {}

  getPreference(
    product: 'bop' | 'wc',
    classCode: number,
    state: string
  ): Observable<PreferenceLevelResponse | null> {
    let options = {};
    if (state) {
      options = {
        params: {
          state,
        },
      };
    }
    // GET /v4/product/:product(bop|wc)/code/:classCode/preference
    const url = `${API_V4_BASE}/product/${product}/code/${classCode}/preference`;
    return this.http.get<PreferenceLevelResponse>(url, options).pipe(
      catchError((error: HttpErrorResponse) => {
        this.sentryService.notify('Failed to retrieve preference info', {
          severity: 'error',
          metaData: {
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });

        return observableOf(null);
      })
    );
  }

  getPreferenceForMultipleCodes(
    product: 'bop' | 'wc',
    classCodes: number[],
    state: string
  ): Observable<PreferenceLevelResponse | null> {
    const params: { classCodes: number[]; state?: string } = {
      classCodes,
    };

    if (state) {
      params.state = state;
    }

    // GET /v4/product/:product(bop|wc)/preference
    const url = `${API_V4_BASE}/product/${product}/preference`;
    return this.http.get<PreferenceLevelResponse>(url, { params }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.sentryService.notify('Failed to retrieve preference info', {
          severity: 'error',
          metaData: {
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });

        return observableOf(null);
      })
    );
  }

  getPreferenceByJobNumber(
    product: 'bop' | 'wc',
    jobNumber: string
  ): Observable<PreferenceLevelResponse | null> {
    const url = `${API_V4_BASE}/product/${product}/job/${jobNumber}/preference`;
    return this.http.get<PreferenceLevelResponse>(url).pipe(
      catchError((error: HttpErrorResponse) => {
        this.sentryService.notify('Failed to retrieve preference info', {
          severity: 'error',
          metaData: {
            underlyingErrorMessage: error && error.message,
            underlyingError: error,
          },
        });

        return observableOf(null);
      })
    );
  }
}
