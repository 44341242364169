import { BopGWPolicyPayment } from 'app/features/attune-bop/models/bop-gw-policy-payment';
import {
  BOP_V2_UW_COMPANY,
  CC_PROCESSING_FEE_EXCLUSIONS,
} from 'app/features/attune-bop/models/constants';

export const MONTHLY_NUMBER_OF_PAYMENTS = 10;
export const ANNUAL_NUMBER_OF_PAYMENTS = 1;

export class BopGWPolicyPaymentPresenter {
  installments: number;
  downPaymentPremium: number;
  downPaymentTax: number;
  downPaymentSubtotal: number;
  installmentPremium: number;
  estimatedTotalIntegral: number;
  estimatedTotalFractional: number;
  downPaymentPercent: number;
  bopVersion: 1 | 2;
  technologyFee = 0;
  processingFee = 0;
  hasCcProcessingFee = true;

  constructor(
    bopPolicyPayment: BopGWPolicyPayment,
    excessPolicyPayment: BopGWPolicyPayment | null,
    numberOfPayments: number
  ) {
    this.installments = numberOfPayments - 1;
    this.downPaymentPremium = bopPolicyPayment.getDownPaymentPremium(numberOfPayments);
    this.downPaymentTax = bopPolicyPayment.getDownPaymentTax();
    this.downPaymentSubtotal = this.downPaymentPremium + this.downPaymentTax;
    this.installmentPremium = bopPolicyPayment.getInstallment(numberOfPayments);
    this.bopVersion = bopPolicyPayment.uwCompanyCode === BOP_V2_UW_COMPANY ? 2 : 1;
    this.hasCcProcessingFee =
      this.bopVersion === 2 &&
      !CC_PROCESSING_FEE_EXCLUSIONS.includes(bopPolicyPayment.policyBaseState);

    if (excessPolicyPayment !== null) {
      this.installmentPremium += excessPolicyPayment.getInstallment(numberOfPayments);

      this.downPaymentPremium += excessPolicyPayment.getDownPaymentPremium(numberOfPayments);
      this.downPaymentTax += excessPolicyPayment.getDownPaymentTax();
      this.downPaymentSubtotal = this.downPaymentPremium + this.downPaymentTax;
    }
    if (bopPolicyPayment.uwCompanyCode === BOP_V2_UW_COMPANY) {
      this.technologyFee = bopPolicyPayment.getTechnologyFee();
      this.processingFee = bopPolicyPayment.getProcessingFee();
    }
    const feesTotal = this.technologyFee + this.processingFee;
    this.downPaymentSubtotal += feesTotal;
    const estimatedTotal =
      this.downPaymentPremium +
      this.installments * this.installmentPremium +
      this.downPaymentTax +
      feesTotal;
    this.estimatedTotalIntegral = Math.trunc(estimatedTotal);
    this.estimatedTotalFractional = (estimatedTotal - this.estimatedTotalIntegral) * 100;

    this.downPaymentPercent =
      this.installments === 0
        ? 0
        : Math.round((100 * this.downPaymentPremium) / this.estimatedTotalIntegral);
  }
}
