import { Subscription, forkJoin } from 'rxjs';
import * as moment from 'moment';
// Libraries
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { every, get, flatMap } from 'lodash';

// Models
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { GetQuoteResponse } from 'app/workers-comp/attune/models/quote.model';
import {
  DisplayConfiguration,
  DisplayConfigurationGroup,
  isDisplayConfigGroup,
} from 'app/shared/models/submission-summary';

// Services
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { GWBindService } from 'app/shared/services/gw-bind.service';
import { formatMoneyNoCents } from 'app/shared/helpers/number-format-helpers';
import { AttuneWCQuoteService } from 'app/workers-comp/attune/services/attune-wc-quote.service';
import { ALL_DEDUCTIBLE_OPTIONS, EMP_LIABILITY_LIMITS_OPTIONS } from '../../constants';
import { OrganizationTypeService } from 'app/shared/services/organization-type.service';
import {
  GW_NEW_ACCOUNT_TEMP_EMAIL_ADDRESS,
  GW_NEW_ACCOUNT_TEMP_PHONE_NUMBER,
} from 'app/features/attune-bop/models/constants';

@Component({
  selector: 'app-attune-wc-submission-summary',
  templateUrl: './attune-wc-submission-summary.component.html',
})
export class AttuneWcSubmissionSummaryComponent implements OnInit, OnDestroy {
  accountNumber: string;
  quoteNumber: string;
  createdAt: string;
  sub: Subscription = new Subscription();

  insuredAccount: InsuredAccount;
  getQuoteResponse: GetQuoteResponse;

  accountDisplayConfiguration: DisplayConfigurationGroup[];
  quoteDisplayConfiguration: DisplayConfigurationGroup[];
  downloadedAt = moment.utc().format('M/D/YYYY h:mm A') + ' UTC';

  constructor(
    private attuneWcQuoteService: AttuneWCQuoteService,
    private insuredAccountService: InsuredAccountService,
    private bindService: GWBindService,
    private route: ActivatedRoute,
    private orgTypeService: OrganizationTypeService
  ) {}

  ngOnInit() {
    this.accountNumber = this.route.snapshot.params['accountId'];
    this.quoteNumber = this.route.snapshot.params['quoteId'];

    const getAccount$ = this.insuredAccountService.get(this.accountNumber);
    const getQuote$ = this.attuneWcQuoteService.getQuote(this.quoteNumber);
    const policyDetails$ = this.bindService.getQuoteDetails(this.quoteNumber);
    this.sub.add(
      forkJoin([getAccount$, getQuote$, policyDetails$]).subscribe(
        ([insuredAccount, getQuoteResponse, policyDetails]) => {
          this.createdAt = moment(policyDetails.createdAt).utc().format('M/D/YYYY h:mm A') + ' UTC';
          this.insuredAccount = insuredAccount;
          this.getQuoteResponse = getQuoteResponse;
          this.createAccountDisplayConfiguration();
          this.createQuoteDisplayConfiguration();
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  createQuoteDisplayConfiguration() {
    this.quoteDisplayConfiguration = [
      this.createBasicsInfoConfiguration(),
      ...this.createLocationsConfiguration(),
      this.createExecutiveElectionsConfiguration(),
      this.createAlwaysHiddenFieldsConfiguration(),
    ];
  }

  createAccountDisplayConfiguration() {
    this.accountDisplayConfiguration = [
      {
        heading: 'Account details',
        children: [
          {
            keyName: 'id',
            displayName: 'Account number',
          },
          {
            keyName: 'companyName',
            displayName: 'Company name',
          },
          {
            keyName: 'doingBusinessAs',
            displayName: 'DBA or Operating Name',
            hide: !this.insuredAccount.doingBusinessAs,
          },
          {
            keyName: 'addressLine1',
            displayName: 'Address line 1',
          },
          {
            keyName: 'addressLine2',
            displayName: 'Address line 2',
            hide: !this.insuredAccount.addressLine2,
          },
          {
            keyName: 'city',
            displayName: 'City',
          },
          {
            keyName: 'state',
            displayName: 'State',
          },
          {
            keyName: 'zip',
            displayName: 'Zip',
          },
          {
            keyName: 'emailAddress',
            displayName: 'Email Address',
            hide: this.insuredAccount.emailAddress === GW_NEW_ACCOUNT_TEMP_EMAIL_ADDRESS,
          },
          {
            keyName: 'additionalEmailAddress',
            displayName: 'Additional email address',
            hide: !this.insuredAccount.additionalEmailAddress,
          },
          {
            keyName: 'phoneNumber',
            displayName: 'Phone number',
            formatter: (rawValue: string) => {
              const digitGroups = /(\d{3})(\d{3})(\d+)/.exec(rawValue);
              if (!digitGroups) {
                return rawValue;
              }
              return `${digitGroups[1]}-${digitGroups[2]}-${digitGroups[3]}`;
            },
            hide: this.insuredAccount.phoneNumber === GW_NEW_ACCOUNT_TEMP_PHONE_NUMBER,
          },
          {
            keyName: 'website',
            displayName: 'Website',
            hide: !this.insuredAccount.website,
          },
        ],
      },
    ];
  }

  /**
   *
   * @returns DisplayConfiguration[] - fields that are always hidden , e.g. request ids that are not explicitly hidden in nested configurations.
   */
  createAlwaysHiddenFieldsConfiguration(): DisplayConfigurationGroup {
    return {
      heading:
        'hidden fields, all fields must be explicitly hidden or added to the display configuration',
      hide: true,
      children: [
        {
          // Quote number will be displayed elsewhere on the summary.
          keyName: 'quoteNumber',
          hide: true,
        },
        // Account number will be displayed elsewhere on the summary.
        {
          keyName: 'accountNumber',
          hide: true,
        },
        {
          keyName: 'tsRequestId',
          hide: true,
        },
      ],
    };
  }

  createBasicsInfoConfiguration(): DisplayConfigurationGroup {
    return {
      heading: 'Basic info',
      children: [
        {
          keyName: 'basicInfo.effectiveDate',
          displayName: 'Effective Date',
        },
        {
          keyName: 'basicInfo.employerIdentificationNumber',
          displayName: 'FEIN',
        },
        {
          keyName: 'basicInfo.organizationType',
          displayName: 'Organization type',
          formatter: (string: string) => this.orgTypeService.getOrgTypeName(string),
        },
        {
          keyName: 'basicInfo.riskId',
          displayName: 'Risk Id',
        },
        {
          keyName: 'basicInfo.experienceMod',
          displayName: 'Experience Modifier',
        },
        {
          keyName: 'basicInfo.yearsInBusiness',
          displayName: 'Years in business',
        },
        {
          keyName: 'basicInfo.employersLiabilityLimits',
          displayName: "Employer's Liability Limits (Each Claim, Each Employee, Policy Limit)",
          formatter: (string: string) => EMP_LIABILITY_LIMITS_OPTIONS[string],
        },
        {
          keyName: 'basicInfo.deductible',
          displayName: 'Deductible',
          formatter: (string: string) => ALL_DEDUCTIBLE_OPTIONS[string],
        },
        {
          keyName: 'basicInfo.waiverOfSubrogation.hasWaiverOfSubrogation',
          displayName: 'Has waiver of subrogation?',
        },
        {
          keyName: 'basicInfo.waiverOfSubrogation.waiverType',
          displayName: 'Waiver type',
        },
        {
          heading: 'Specific waivers',
          children: [
            ...flatMap(
              this.getQuoteResponse.basicInfo.waiverOfSubrogation?.specificWaivers,
              (_specificWaiver, index): DisplayConfiguration[] => {
                return [
                  {
                    keyName: `basicInfo.waiverOfSubrogation.specificWaivers[${index}].address.addressLine1`,
                    displayName: 'Address line 1',
                  },
                  {
                    keyName: `basicInfo.waiverOfSubrogation.specificWaivers[${index}].address.addressLine2`,
                    displayName: 'Address line 2',
                  },
                  {
                    keyName: `basicInfo.waiverOfSubrogation.specificWaivers[${index}].address.city`,
                    displayName: 'City',
                  },
                  {
                    keyName: `basicInfo.waiverOfSubrogation.specificWaivers[${index}].address.state`,
                    displayName: 'State',
                  },
                  {
                    keyName: `basicInfo.waiverOfSubrogation.specificWaivers[${index}].address.zip`,
                    displayName: 'Zip',
                  },
                  {
                    keyName: `basicInfo.waiverOfSubrogation.specificWaivers[${index}].nameOrOrg`,
                    displayName: 'Name of person or organization',
                  },
                  {
                    keyName: `basicInfo.waiverOfSubrogation.specificWaivers[${index}].description`,
                    displayName: 'Description',
                  },
                ];
              }
            ),
          ],
        },
        {
          keyName: 'basicInfo.numberOfLocations',
          displayName: 'Number of locations',
        },
        {
          keyName: 'basicInfo.bankruptcyNotDischarged',
          displayName: 'Insured currently in bankruptcy which has not been discharged?',
        },
        {
          keyName: 'basicInfo.lessThan100PercentOwnership',
          displayName: 'Does the insured own 100% of the business?',
          formatter: (value) => {
            // true here means lessThan100PercentOwnership
            if (value === true) {
              return 'No';
            }
            return 'Yes';
          },
        },
      ],
    };
  }

  createLocationsConfiguration(): DisplayConfigurationGroup[] {
    return this.getQuoteResponse.locations.map(
      (location, locationIndex): DisplayConfigurationGroup => {
        return {
          heading: `Location ${locationIndex + 1}`,
          children: [
            {
              keyName: `locations[${locationIndex}].locationNum`,
              hide: true,
            },
            {
              keyName: `locations[${locationIndex}].address.addressLine1`,
              displayName: 'Address line 1',
            },
            {
              keyName: `locations[${locationIndex}].address.addressLine2`,
              displayName: 'Address line 2',
            },
            {
              keyName: `locations[${locationIndex}].address.city`,
              displayName: 'City',
            },
            {
              keyName: `locations[${locationIndex}].address.state`,
              displayName: 'State',
            },
            {
              keyName: `locations[${locationIndex}].address.zip`,
              displayName: 'Zip',
            },
            {
              heading: 'Employee classifications',
              children: [
                ...flatMap(
                  location.classCodes,
                  (classCode, classificationIndex): DisplayConfiguration[] => {
                    return [
                      {
                        keyName: `locations[${locationIndex}].classCodes[${classificationIndex}].classCode`,
                        displayName: 'Class Code',
                      },
                      {
                        keyName: `locations[${locationIndex}].classCodes[${classificationIndex}].description`,
                        displayName: 'Class Description',
                      },
                      {
                        keyName: `locations[${locationIndex}].classCodes[${classificationIndex}].payroll`,
                        displayName: 'Payroll',
                        formatter: formatMoneyNoCents,
                      },
                      {
                        keyName: `locations[${locationIndex}].classCodes[${classificationIndex}].numberOfEmployees`,
                        displayName: 'Number of employees',
                      },
                    ];
                  }
                ),
              ],
            },
          ],
        };
      }
    );
  }

  createExecutiveElectionsConfiguration(): DisplayConfigurationGroup {
    return {
      heading: 'Executive elections',
      children: [
        ...flatMap(
          this.getQuoteResponse.executiveElections.elections,
          (_election, index): DisplayConfiguration[] => {
            return [
              {
                keyName: `executiveElections.elections[${index}].firstName`,
                displayName: 'First name',
              },
              {
                keyName: `executiveElections.elections[${index}].lastName`,
                displayName: 'Last name',
              },
              {
                keyName: `executiveElections.elections[${index}].ownership`,
                displayName: 'Ownership %',
                formatter: (string) => `${string}%`,
              },
              {
                keyName: `executiveElections.elections[${index}].title`,
                displayName: 'Title',
              },
              {
                keyName: `executiveElections.elections[${index}].isIncluded`,
                displayName: 'Is included?',
              },
              {
                keyName: `executiveElections.elections[${index}].locationNum`,
                hide: true,
              },
            ];
          }
        ),
        {
          keyName: 'executiveElections.attestation',
          hide: this.getQuoteResponse.executiveElections.elections.length === 0,
          formatter: () => {
            // We need to format this value because it will always be "false" in the get payload as we require re-confirmation for edits
            // There is validation in the form flow to prevent submitting without checking the attestation if there are elections made.
            if (this.getQuoteResponse.executiveElections.elections.length > 0) {
              return 'Yes';
            }
            return 'No';
          },
        },
      ],
    };
  }

  getFormField(
    data: GetQuoteResponse | InsuredAccount,
    path: string,
    formatter: (rawValue: number | string | boolean) => string
  ) {
    let value = get(data, path);

    if (typeof value === 'boolean' && !formatter) {
      return value ? 'Yes' : 'No';
    }
    if (formatter) {
      value = formatter(value);
    }
    return value;
  }

  fieldGroupIsHidden(fieldGroup: DisplayConfigurationGroup) {
    return every(fieldGroup.children, this.isHidden.bind(this));
  }

  isHidden(
    fieldInfo: DisplayConfiguration | DisplayConfigurationGroup,
    data: GetQuoteResponse | InsuredAccount
  ): boolean {
    if (!data) {
      return true;
    }
    if (fieldInfo.hide) {
      return true;
    }

    if (isDisplayConfigGroup(fieldInfo)) {
      return fieldInfo.children.every((child) => this.isHidden(child, data));
    }

    const fieldValue = get(data, fieldInfo.keyName, undefined);
    return fieldValue === undefined || fieldValue === null || fieldValue === 'undefined';
  }
}
